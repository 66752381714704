import { Component, EventEmitter, Inject, Injector, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { Observable, of, Subscription, take } from 'rxjs';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { Constants } from 'src/app/shared/constants';
import { Entity } from 'src/app/shared/models/entity';
import { Field, FieldType } from 'src/app/shared/models/field';
import { EntitiesDataService } from 'src/app/shared/services/entities-data.service';
import { UserService } from 'src/app/shared/services/user.service';
import { convertBooleanToYesNo, convertYesNoToBoolean, isNullOrUndefined } from 'src/app/shared/utilities';
import { dateTimeYearValidator, dateYearValidator, numberValidator } from 'src/app/shared/validators';
import { TranslateService } from '@ngx-translate/core';
import { ChoicesetOptionsComponent } from '../choiceset-options/choiceset-options.component';
import { RecordFlyoutComponent } from 'src/app/shared/components/record-flyout/record-flyout.component';
import { FileAttachmentComponent } from 'src/app/shared/components/file-attachment/file-attachment.component';

export enum UpsertDataDialogMode {
  create = 'Add',
  update = 'Edit'
}

export enum OutputMode {
  upsert = 'Upsert',
  delete = 'Delete',
}

export interface UpsertDataDialogData {
  mode: UpsertDataDialogMode;
  fields: Field[];
  entity: Entity;
  userEntity: Entity;
  userEntityField: Field;
  refreshEntity: EventEmitter<Entity>;
}

@Component({
  selector: 'upsert-data-dialog',
  templateUrl: './upsert-data-dialog.component.html',
  styleUrls: ['./upsert-data-dialog.component.scss']
})
export class UpsertDataDialogComponent implements OnDestroy {

  public dataForm: any;

  public filesToUpload: Record<string, File> = {};

  public isLoading: boolean;
  public ReadEntityData: number = Constants.ReadEntityData;
  public CreateEntityData: number = Constants.CreateEntityData;
  public UpdateEntityData: number = Constants.UpdateEntityData;
  public UpdateEntityScheme: number = Constants.UpdateEntityScheme;
  public ReadEntityScheme: number = Constants.ReadEntityScheme;
  public shouldShowProgressBox: boolean;

  private subscription: Subscription = new Subscription();

  private dataFormSnapshot: any;
  public refreshEntity: EventEmitter<Entity>;
  @ViewChildren('choiceSetComponents') choiceSetComponents: QueryList<ChoicesetOptionsComponent>;
  @ViewChildren('fileComponents') fileComponents: QueryList<FileAttachmentComponent>;
  @ViewChildren('relationshipComponents') relationshipComponents: QueryList<RecordFlyoutComponent>;

  private createAnother = false;
  private formGroup = {};

  translateService: TranslateService;
  snackBar: MatSnackBar;

  constructor(
    public dialogRef: MatDialogRef<UpsertDataDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: UpsertDataDialogData,
    public entitiesDataService: EntitiesDataService,
    public userService: UserService,
    private fb: UntypedFormBuilder,
    private injector: Injector
  ) {
    this.refreshEntity = data.refreshEntity;
    this.translateService = injector.get<TranslateService>(TranslateService);
    this.snackBar = injector.get<MatSnackBar>(MatSnackBar);

    for (const field of this.data.fields) {

      if (field.isSystemField || field.fieldDisplayType === Constants.Autonumber) {
        continue;
      }

      const control: any[] = [];

      let value: any = null;
      let rawValue = field.value;
      if (this.data.mode === UpsertDataDialogMode.create) {
        rawValue = field.value ? field.value : this.convertDefaultValue(field);
      }

      if (field.type === FieldType.date) {
        value = rawValue ? new Date(rawValue) : field.isRequired ? new Date() : null;
      } else if (field.type === FieldType.dateTime) {
        value = rawValue ? rawValue.replace('Z', '') : field.isRequired ? new Date() : null;
      } else if (field.type === FieldType.number) {
        value = isNullOrUndefined(rawValue) ? null : `${rawValue}`;
      } else {
        value = rawValue;
      }

      control.push(value);

      const validators = [];

      if (field.isRequired) {
        validators.push(Validators.required);
      }

      // add validators here
      if (field.type === FieldType.number) {
        validators.push(numberValidator(!field.isRequired));
      }
      if (field.type === FieldType.date) {
        validators.push(dateYearValidator());
      }
      if (field.type === FieldType.dateTime) {
        validators.push(dateTimeYearValidator());
      }
      if (validators.length > 0) {
        control.push(validators);
      }

      this.formGroup[field.name] = control;
    }

    this.dataForm = this.fb.group(this.formGroup);
    this.dataFormSnapshot = JSON.stringify(this.dataForm && this.dataForm.value);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onFileSelected = (fieldName: string, files: File[]): void => {
    this.filesToUpload[fieldName] = files[0];
  };

  public enterToSubmit() {
    if (!this.isSubmitDisabled()) {
      this.onSubmit();
    }
  }

  public checkFieldDisabled(field: Field): Observable<boolean> {

    if (field.isRbacEnabled) {
      if (this.isCreateMode()) {
        return this.userService.checkFieldPermission([this.CreateEntityData], this.data.entity.id, field.id);
      } else {
        return this.userService.checkFieldPermission([this.UpdateEntityData], this.data.entity.id, field.id);
      }
    } else if (
      field.type === FieldType.relationship
      && field.referenceField
      && field.referenceField.isRbacEnabled) {
      if (this.isCreateMode()) {
        return this.userService.checkFieldPermission([this.CreateEntityData], field.referenceEntity.id, field.referenceField.id);
      } else {
        return this.userService.checkFieldPermission([this.UpdateEntityData], field.referenceEntity.id, field.referenceField.id);
      }
    } else {
      if (this.isCreateMode()) {
        return this.userService.checkPermission([this.CreateEntityData], this.data.entity.id);
      } else {
        return this.userService.checkPermission([this.UpdateEntityData], this.data.entity.id);
      }
    }
  }

  public toggleCreateAnother() {
    this.createAnother = !this.createAnother;
  }

  public onSubmit(): void {
    this.isLoading = true;
    this.shouldShowProgressBox = Object.keys(this.filesToUpload).length > 0 && this.filesToUpload.att !== undefined;
    const recordId = this.entitiesDataService.getRecordId(this.data.entity.fields);

    // do value conversion for different data types
    for (const field of this.data.fields) {
      if (field.type === FieldType.boolean) {
        this.dataForm.value[field.name] = convertYesNoToBoolean(this.dataForm.value[field.name]);
      } else if (field.type === FieldType.number && !isNullOrUndefined(this.dataForm.value[field.name])) {
        this.dataForm.value[field.name] = this.dataForm.value[field.name].length > 0 ? +this.dataForm.value[field.name] : null;
      } else if (field.type === FieldType.date) {
        this.dataForm.value[field.name] =
          !!this.dataForm.value[field.name]
            ? moment(this.dataForm.value[field.name]).format('L')
            : null;
      }
    }

    const upsertAction = this.getDataFormUpsertAction(recordId);

    const insertSub = upsertAction.pipe(take(1)).subscribe({
      next: (result) => {
        this.uploadFileAttachments(result).subscribe({
          next: (hasFailures) => {
            if (hasFailures) {
              this.isLoading = false;
              this.shouldShowProgressBox = false;
            } else {
              if (this.createAnother) {
                this.resetForm();
              } else {
                this.dialogRef.close({
                  mode: OutputMode.upsert,
                  data: 'succeed'
                });
                this.refreshEntity.emit();
              }
            }
          }
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.shouldShowProgressBox = false;
        this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
      }
    });
    this.subscription.add(insertSub);
  }

  private resetForm() {
    this.isLoading = false;
    this.dataForm = this.fb.group(this.formGroup);
    this.filesToUpload = {};
    if (this.fileComponents) {
      this.fileComponents.toArray().forEach((fileComponent) => fileComponent.onDismissSelectedFile());
    }
    if (this.choiceSetComponents) {
      this.choiceSetComponents.toArray().forEach((choiceSetComponent) => choiceSetComponent.onClearValue());
    }
    if (this.relationshipComponents) {
      this.relationshipComponents.toArray().forEach((relationshipComponent) => relationshipComponent.onClearValue());
    }
  }

  public isSubmitDisabled(): boolean {
    return (this.isPristine() && this.data.mode !== UpsertDataDialogMode.create) || this.dataForm.invalid;
  }

  public onDismiss(): void {

    this.dialogRef.close();
  }

  public shouldShow(field: Field, type: string): boolean {

    if (field.isSystemField || field.fieldDisplayType === Constants.Autonumber) {
      return false;
    }

    switch (type) {
    case 'relation':
      return field.type === FieldType.relationship && field.fieldDisplayType !== Constants.File;
    case 'choiceset':
      return field.type === FieldType.choiceSetSingle || field.type === FieldType.choiceSetMultiple;
    case 'number':
      return field.type === FieldType.number;
    case 'boolean':
      return field.type === FieldType.boolean;
    case 'date':
      return field.type === FieldType.date;
    case 'dateTime':
      return field.type === FieldType.dateTime;
    case 'file':
      return field.type === FieldType.relationship && field.fieldDisplayType === Constants.File;
    default:
      return field.type === FieldType.text;
    }
  }

  public isCreateMode(): boolean {
    return this.data.mode === UpsertDataDialogMode.create;
  }

  private isPristine(): boolean {
    const hasFilesToUpload = Object.values(this.filesToUpload).some(f => !!f);
    return !hasFilesToUpload && this.isDataFormPristine();
  }

  private isDataFormPristine(): boolean {
    const current = JSON.stringify(this.dataForm.value);
    return current === this.dataFormSnapshot;
  }

  private getDataFormUpsertAction(recordId: string): Observable<Record<string, string>> {
    if (this.data.mode === UpsertDataDialogMode.create) {
      return this.entitiesDataService.insertEntityData(this.data.entity.id, this.dataForm.value);
    }

    if (!this.isDataFormPristine()) {
      return this.entitiesDataService.updateEntityData(this.data.entity.id, recordId, this.dataForm.value);
    }

    // If we reach here, then the user has only changed file fields in the dialog.
    // Return the necessary info of the record so the file upload logic can follow up.
    return of({ Id: recordId });
  }

  private uploadFileAttachments(record: Record<string, string>): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const fileUploadStatuses: Record<string, boolean> = {};
      const fileCount = Object.values(this.filesToUpload).filter(f => !!f).length;

      if (fileCount === 0) {
        observer.next(false);
        observer.complete();
        return;
      }

      const handleUploadResult = (fieldName: string, result: boolean): void => {
        fileUploadStatuses[fieldName] = result;

        if (Object.keys(fileUploadStatuses).length === fileCount) {
          const hasFailures = Object.values(fileUploadStatuses).some(s => !s);
          observer.next(hasFailures);
          observer.complete();
        }
      };

      Object.keys(this.filesToUpload).forEach(fieldName => {
        const file = this.filesToUpload[fieldName];

        if (file) {
          const sub = this.entitiesDataService.uploadFile(this.data.entity.name, fieldName, record['Id'], file).subscribe(
            () => {
              handleUploadResult(fieldName, true);
            }, err => {
              handleUploadResult(fieldName, false);

              if (err.errorText != null)
              {
                this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: err });
              }
              else{
                this.snackBar.openFromComponent(SnackBarComponent,
                  { duration: 5000, verticalPosition: 'top',
                    data: {text: this.translateService.get('File.FailedText', {value: sessionStorage.getItem(Constants.TraceId)})} });
              }
            });
          this.subscription.add(sub);
        }
      });
    });
  }

  private convertDefaultValue(field: Field): any {
    if (field.type === FieldType.boolean && field.defaultValue) {
      const booleanValue = JSON.parse(field.defaultValue);
      return convertBooleanToYesNo(booleanValue);
    }
    return field.defaultValue;
  }
}
