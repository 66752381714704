import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ResizeEvent } from 'angular-resizable-element';
import * as moment from 'moment';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { ConfirmationDialogComponent, ConfirmationType } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from 'src/app/shared/components/error-dialog/error-dialog.component';
import { SnackBarComponent, SnackBarMode } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { Constants } from 'src/app/shared/constants';
import {
  BatchDeleteResponse,
  Entity,
  EntityType,
  Expansion,
  FilterLogicalOperator,
  QueryFilterGroup,
  SortOption
} from 'src/app/shared/models/entity';
import { SortData } from 'src/app/shared/models/environment';
import { ErrorType, Events } from 'src/app/shared/models/events';
import { Field, FieldType } from 'src/app/shared/models/field';
import { AdvancedQueryRequest, EntitiesDataService, FilterFieldEntry } from 'src/app/shared/services/entities-data.service';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { TelemetryService } from 'src/app/shared/services/telemetry.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AdvancedSearchComponent, AndOrKey, Condition, ConditionKey, QueryRecord } from '../advanced-search/advanced-search.component';
import {
  convertBooleanToYesNo,
  convertYesNoToBoolean,
  getTableSortAriaHelper,
  isNullOrUndefined,
  isNumber,
  isSystemEntity,
  isSystemUserEntity } from 'src/app/shared/utilities';
import { OutputMode, UpsertDataDialogComponent, UpsertDataDialogMode } from '../upsert-data-dialog/upsert-data-dialog.component';
import { UpsertFileDialogComponent, UpsertFileDialogData } from '../upsert-file-dialog/upsert-file-dialog.component';
import { BasicSearchComponent } from '../basic-search/basic-search.component';
import { ChoiceSetMember } from 'src/app/shared/models/choiceset';
import { numberValidatorWithoutControl } from 'src/app/shared/validators';
import { formatDate } from '@angular/common';

export interface SelectedColumn {
  row: any;
  column: string;
}

export interface Pagination {
  previousPageIndex: number;
  pageIndex: number;
  pageSize: number;
  length: number;
}

export interface NameWithIsSystemField {
  name: string;
  isSearchableSystemField: boolean;
}

export interface SearchColumn {
  name: string;
  fieldType: string;
  choiceSetId?: string;
}

export enum ConditionToSymbolMap {
  contains = "contains",
  notcontains = "not contains",
  equals = "=",
  doesnotequal = "!=",
  endswith = "endswith",
  startswith = "startswith",
  isempty = "=",
  isnotempty = "!=",
  ismorethan = ">",
  islessthan = "<",
  isnotmorethan = "<=",
  isnotlessthan = ">=",
  isin = "in",
  isnotin = "not in",
}

@Component({
  selector: 'list-data',
  templateUrl: './list-data.component.html',
  styleUrls: ['./list-data.component.scss'],
  providers: [EntitiesDataService],
  encapsulation: ViewEncapsulation.None
})
export class ListDataComponent implements OnInit, OnDestroy, OnChanges {

  @Input('entity')
  public entity: Entity;

  @Input('entities')
  public entities: Entity[];

  @Input('addDataToolTip')
  public addDataToolTip: () => Observable<string>;

  @Input('advancedQueryRequest')
  public advancedQueryRequest: AdvancedQueryRequest;

  @Output()
  public refreshEntity: EventEmitter<Entity> = new EventEmitter<Entity>();

  public data: any[] = [];
  public nextPageDataCache: any[] = [];
  public selection = new SelectionModel<any>(true, []);
  public dataSnapshot: any[] = [];
  public columns: string[] = [];
  public ariaHelper = getTableSortAriaHelper();
  public searchValue: string;
  public searchValueDebounced: string;
  public searchValueUpdate = new Subject<string>();
  public selectedColumn: SelectedColumn = null;
  public loadingColumn: SelectedColumn = null;
  public editValue: any;
  public shouldDisableEditSave = true;
  public length = 0;
  public pagination: Pagination;
  public isLoading = false;
  public initialLoad = false;
  public isColumnLoading = false;
  public relationInputType = 'RELATION';
  public fileAttachmentInputType = 'FILEATTACHMENT';
  public choicesetType = 'CHOICESET';
  public ownerType = Constants.RecordOwner;
  public choiceSetMemberMap: Record<string, ChoiceSetMember[]> = {};
  public isRlpEnabled: boolean;
  public isQueryBuilderEnabled: boolean;
  public columnErrorText: Observable<string>;
  public advancedSearchFilter: string;
  public savedQueryRecords: QueryRecord[];
  public savedQueryFilters: FilterFieldEntry[];
  public savedOperator: FilterLogicalOperator;
  public advancedSearchTranslationMap = {};
  public isLoadingChoiceSets = false;

  public UpdateEntityData: number = Constants.UpdateEntityData;
  public DeleteEntityData: number = Constants.DeleteEntityData;
  public CreateEntityData: number = Constants.CreateEntityData;

  public showNoPermission = false;
  public isSystemEntity = isSystemEntity;
  public isSystemUserEntity = isSystemUserEntity;

  @ViewChild('acceptedColumnsSelected') public acceptedColumnsSelected: MatSelect;
  public searchColumns: SearchColumn[] = [];
  public acceptedSearchColumns: string[] = [];
  public savedFields: SearchColumn[] = [];
  public selectedAcceptedSearchColumns: string[];
  public displayNamesToNameAndSystemType: {} = {};
  public noSelectedSearchColumns = false;
  public areAllAcceptedColumnsSelected = true;
  public onlyNumberColumnsChosen: boolean;

  private originalValue: any;
  private errorNumPrefix = "Error Number: ";
  private subscription: Subscription = new Subscription();
  private fieldMap: {} = {};
  private fieldNameMap = new Map([
    ['CreatedBy', 'Created By'],
    ['CreateTime', 'Create Time'],
    ['UpdatedBy', 'Updated By'],
    ['UpdateTime', 'Update Time'],
    ['RecordOwner', 'Record Owner']
  ]);
  private loadingText = '';
  private sortOptionCreateTime: SortOption[] = [{ fieldName: 'CreateTime', isDescending: true }];
  private acceptedSearchColumnTypes = new Set([FieldType.uniqueid, FieldType.text, FieldType.number, FieldType.autonumber]);
  private searchDebounceTime = 1000;
  private fileNestedFields = [
    {name: 'Name', fieldType: FieldType.text},
    {name: 'Size', fieldType: FieldType.number},
    {name: 'Type', fieldType: FieldType.text}
  ] as SearchColumn[];
  private systemNestedFields = [
    {name: 'CreateTime', fieldType: FieldType.dateTime},
    {name: 'Email', fieldType: FieldType.text},
    {name: 'Id', fieldType: FieldType.uniqueid},
    {name: 'IsActive', fieldType: FieldType.boolean},
    {name: 'Name', fieldType: FieldType.text},
    {name: 'Type', fieldType: FieldType.text},
    {name: 'UpdateTime', fieldType: FieldType.dateTime}
  ] as SearchColumn[];

  constructor(
    public dialog: MatDialog,
    public userService: UserService,
    private entityDataService: EntitiesDataService,
    private snackBar: MatSnackBar,
    private telemetryService: TelemetryService,
    private translateService: TranslateService,
    private featureService: FeatureService
  ) { }

  public ngOnInit() {
    this.telemetryService.startTimedEvent(Events.EntityDataView);
    this.featureService.isEnabled(FeatureKey.EntityDataRecordPermission).subscribe(result => {
      this.isRlpEnabled = result;
    });

    this.featureService.isEnabled(FeatureKey.QueryBuilder).subscribe(result => {
      this.isQueryBuilderEnabled = result;
    });

    this.buildAdvancedSearchTranslationMap();
    this.searchValueUpdate.pipe(debounceTime(this.searchDebounceTime)).subscribe(searchValue => {
      this.searchValueDebounced = searchValue;
      this.onSearch();
    });
    this.isLoading = true;
    this.initialLoad = true;
    this.columnErrorText = this.translateService.get('ListData.ColumnErrorText1');
    if (this.advancedQueryRequest) {
      this.telemetryService.logEvent(Events.AdvancedSearchQueryThroughDeepLink);
      this.savedOperator = this.advancedQueryRequest.filterLogicalOperator;
      this.savedQueryFilters = this.advancedQueryRequest.queryFilterFields;
      this.buildQueryRecordsFromFilter(this.savedQueryFilters);
      this.initializeSearchColumns();
      this.assembleData(this.savedQueryFilters, this.savedOperator);
      window.history.replaceState(null, '', window.location.href.substring(0, window.location.href.indexOf('?')));
    }
  }

  public ngOnChanges() {
    if (!this.advancedQueryRequest) {
      this.resetTableView();
      this.assembleData();
    }
    this.loadChoiceSetMembers();
  }

  public onClickRefresh() {
    this.isLoading = true;
    this.assembleData(this.savedQueryFilters, this.savedOperator);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public validateEditValue(type: string, column: string): void {
    // handle required
    const field = (this.fieldMap[column] as Field);
    this.shouldDisableEditSave = false;
    this.columnErrorText = null;
    if (field.isRequired && !this.editValue) {
      this.shouldDisableEditSave = true;
      this.columnErrorText = this.translateService.get('ListData.ColumnErrorText2');
    }

    switch (type) {
    case 'number':
      const regex = new RegExp('^(-?)(0|([1-9][0-9]*))(\\.[0-9]+)?$');
      const match = regex.test(this.editValue);
      if (match || !this.editValue) {
        this.shouldDisableEditSave = false;
      } else {
        this.shouldDisableEditSave = true;
        this.columnErrorText = this.translateService.get('ListData.ColumnErrorText3');
      }
      break;
    default:
      break;
    }
  }

  public onSearch() {
    if (this.isQueryBuilderEnabled) {
      if (!this.searchValue) {
        this.savedQueryFilters = [];
        this.assembleData();
        return;
      } else {
        this.onlyNumberColumnsChosen = false;
        this.noSelectedSearchColumns = false;
        const queryFilters = this.getQueryFilters();
        if (this.selectedAcceptedSearchColumns.length === 0) {
          this.noSelectedSearchColumns = true;
        } else if (queryFilters.length === 0) {
          this.onlyNumberColumnsChosen = true;
        } else {
          this.savedQueryFilters = queryFilters;
          this.savedOperator = 1;
          this.assembleData(queryFilters, 1);
        }
      }
    } else {
      this.data = [...this.dataSnapshot];

      if (!this.searchValue) {
        return;
      } else {
        this.data = [...this.data].filter(item => {

          item.CreateTime = this.convertISOtoLocalDateTime(item.CreateTime);

          item.UpdateTime = this.convertISOtoLocalDateTime(item.UpdateTime);

          const values = Object.values(item);

          // Since the last item of array values contains all information including user id, time without conversion, we need remove it
          // Otherwise, the search will be impacted
          values.pop();

          return JSON.stringify(values).toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) > -1;
        });
      }
    }
  }

  public isItemSelected() {
    return !(this.selection.selected && this.selection.selected.length > 0);
  }

  public onDeleteData(): void {
    const ids = this.selection.selected.map(entry => entry['Id']);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: this.translateService.get('ListData.DeleteData'),
        yesText: this.translateService.get('DeleteText'),
        noText: this.translateService.get('CancelText'),
        confirmationText: this.translateService.get('ListData.ConfirmText'),
        asyncFunc: () => this.entityDataService.deleteEntityDataInBulk(this.entity.id, ids)
      }
    });

    const sub = dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation.result === ConfirmationType.yes) {
        const deleteResult = confirmation.data as BatchDeleteResponse;
        if (deleteResult && deleteResult.failureRecords && deleteResult.failureRecords.length > 0) {
          this.snackBar.openFromComponent(
            SnackBarComponent,
            { duration: 8000, verticalPosition: 'top', data: { errorText: deleteResult.failureRecords[0].error } }
          );
        }
        this.assembleData(this.savedQueryFilters, this.savedOperator);
        this.refreshEntity.emit();
      }
    });
    this.subscription.add(sub);
  }

  public showInputType(column: string): string {
    const field = this.getFieldByName(column);

    if (field.fieldDisplayType === Constants.File) {
      return this.fileAttachmentInputType;
    }

    if (field.name === this.ownerType) {
      return this.ownerType;
    }

    if (field.isForeignKey) {
      return this.relationInputType;
    }

    if ([Constants.ChoiceSetSingle, Constants.ChoiceSetMultiple].includes(field.fieldDisplayType)) {
      return this.choicesetType;
    }

    return field && field.sqlType && field.sqlType.name;
  }

  public onClickEdit(existingData: any): void {

    const dialogMode = existingData ? UpsertDataDialogMode.update : UpsertDataDialogMode.create;
    if (existingData) {
      existingData = this.selection.selected[0];
      this.telemetryService.logEvent(Events.UpdateEntityData);
    } else {
      this.telemetryService.logEvent(Events.AddNewDataClick);
    }

    const permission = dialogMode === UpsertDataDialogMode.update ?
      Constants.UpdateEntityData : Constants.CreateEntityData;
    const dialogRef = this.dialog.open(UpsertDataDialogComponent,
      {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        panelClass: 'custom-sidepane',
        disableClose: true,
        data: {
          mode: dialogMode,
          fields: this.getFieldExistingValue(existingData,
            this.userService.getAccessibleFields(this.entity, permission)),
          entity: this.entity,
          userEntity: this.getUserEntity(),
          userEntityField: this.getUserEntityNameField(),
          refreshEntity: this.refreshEntity,
        }
      });

    const sub = dialogRef.afterClosed().subscribe(output => {
      if (!output) {
        return;
      }

      if (output.mode === OutputMode.delete) {
        this.assembleData(this.savedQueryFilters, this.savedOperator);
      } else if (output.data) {
        if (output.data === 'succeed' || this.savedQueryFilters) {
          this.assembleData(this.savedQueryFilters, this.savedOperator);
        }
      }
    });
    this.subscription.add(sub);
  }

  public onOpenUpsertFileDialog(field: Field, recordId: string): void {
    this.telemetryService.logEvent(Events.UpdateEntityData);

    const data: UpsertFileDialogData = {
      field,
      recordId,
      entity: this.entity
    };

    this.dialog.open(UpsertFileDialogComponent,
      {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        panelClass: 'custom-sidepane',
        data,
      }).afterClosed().subscribe(output => {
      if (output && output.data === 'succeed') {
        this.assembleData(this.savedQueryFilters, this.savedOperator);
      }
    });
  }

  public shouldDisableEdit() {
    return this.selection.selected && this.selection.selected.length === 1;
  }

  public sortData(sort: SortData) {
    this.ariaHelper.setSortOrder(sort.active, sort.direction);

    if (sort.direction === 'asc') {
      this.data = [...this.data].sort((item1, item2) =>
        this.getComparableValue(item1, sort.active).localeCompare(this.getComparableValue(item2, sort.active), undefined, {
          numeric: true
        }));
    } else {
      this.data = [...this.data].sort((item1, item2) =>
        -this.getComparableValue(item1, sort.active).localeCompare(this.getComparableValue(item2, sort.active), undefined, {
          numeric: true
        }));
    }
  }

  public shouldShow(element: any, column: string) {

    return this.selectedColumn && column === this.selectedColumn.column
      && element.Id === this.selectedColumn.row.Id;
  }

  public shouldShowColumnLoading(element: any, column: string) {
    return this.loadingColumn && column === this.loadingColumn.column
      && element.Id === this.loadingColumn.row.Id;
  }

  public shouldShowNoColumnError() {
    return this.selectedAcceptedSearchColumns && this.selectedAcceptedSearchColumns.length === 0;
  }

  public isEditableField(column: string): boolean {
    if (isSystemEntity(this.entity) || this.isAutoNumber(column)) {
      return false;
    }

    return !this.isSystemField(column);
  }

  public isAutoNumber(column: string): boolean {
    return this.getFieldByName(column).fieldDisplayType === Constants.Autonumber;
  }

  public getEditTextTooltip(column: string): Observable<string> {
    if (!this.isEditableField(column)) {
      if (this.isAutoNumber(column)) {
        return this.translateService.get('ListFields.AutonumberTooltip');
      }
      else {
        return this.translateService.get('ListFields.DeleteTooltipSystem');
      }
    }
    return of('');
  }

  public getEditTextLabel(column: string): Observable<string> {
    if (this.isEditableField(column)) {
      return this.translateService.get('ListFields.ClearValueTooltip');
    }
    return of('');
  }

  public onBlur(event: FocusEvent, inlineEditor: Element): void {
    const target = event.relatedTarget as Element;
    if (!target || !(event.currentTarget as Element).classList.contains('edit-yes-no') && !inlineEditor.contains(target)) {
      this.onClickCancel();
    }
  }

  public onClickCancel(): void {
    this.selectedColumn = null;
    this.editValue = null;
    this.shouldDisableEditSave = false;
  }

  public onClickColumn(element: any, column: string) {
    if (!this.isEditableField(column) || this.selectedColumn) {
      return;
    }

    this.checkFieldEditPermission(column).subscribe(result => {
      if (result) {
        this.selectedColumn = { column, row: element };
        const field = this.getFieldByName(column);
        if (field.type === FieldType.date) {
          this.editValue = element[column] ? new Date(element[column]) : null;
        } else if (field.type === FieldType.dateTime) {
          // local time format only honors format YYYY-MM-DDThh:mm:ss eg. '2014-01-02T11:42:13.510', without the trailing Z
          this.editValue = element[column] ? element[column].replace('Z', '') : null;
        } else {
          this.editValue = element[column];
        }
        this.shouldDisableEditSave = false;
      }
    });
  }

  public checkFieldEditPermission(column: string): Observable<boolean> {
    const field = this.getFieldByName(column);

    if (field.isRbacEnabled) {
      return this.userService.checkFieldPermission([this.UpdateEntityData], this.entity.id, field.id);
    } else {
      return this.userService.checkPermission([this.UpdateEntityData], this.entity.id);
    }
  }

  public onClickSaveRelation(element: any, column: string, $event) {
    const field = this.getFieldByName(column);
    if (!element[column]) {
      element[column] = {};
    }
    element[column] = $event;
    this.editValue = element[column];
    this.updateEntityData(element['Id'], field, element, column);
  }

  public onClickSaveChoiceSetMember(element: any, column: string, memberId: number) {
    const field = this.getFieldByName(column);
    this.editValue = memberId;
    element[column] = memberId;
    this.updateEntityData(element['Id'], field, element, column);
  }

  public onClickSave(element: any, column: string) {
    const recordId = element['Id'];

    const field = this.entity.fields.find(field => field.name === column);

    if (field.type === FieldType.dateTime || field.type === FieldType.date) {
      const isValidValue = this.editValue === null || !isNaN(Date.parse(this.editValue));

      if (!isValidValue || (field.isRequired && this.editValue === null)) {
        this.onClickCancel();
        return;
      }

      const isEdited = !element[column] && !!this.editValue
        || !!element[column] && !this.editValue
        || !!element[column] && !!this.editValue && new Date(element[column]).getTime() !== new Date(`${this.editValue}Z`).getTime();
      if (!isEdited) {
        this.onClickCancel();
        return;
      }
    } else if (field.type === FieldType.number && !isNullOrUndefined(this.editValue)) {
      if (this.editValue === element[column]) {
        this.onClickCancel();
        return;
      } else if (this.editValue.length > 0) {
        this.editValue = +this.editValue;
      } else {
        this.editValue = null;
      }

    }

    if (element[column] === this.editValue || this.shouldDisableEditSave) {
      this.onClickCancel();
      return;
    }

    this.originalValue = element[column];
    element[column] = this.editValue;

    if (field.type === FieldType.date) {
      element[column] = this.editValue ? moment(this.editValue).format('L') : null;
      this.editValue = element[column];
    } else if (field.type === FieldType.dateTime) {
      element[column] = `${this.editValue}Z`;
    }
    this.updateEntityData(recordId, field, element, column);
  }

  public updateEntityData(recordId: string, field: Field, element: any, column: string) {
    this.loadingColumn = { column, row: element };

    const request = {};
    if (this.editValue === "") {
      request[column] = null;
    } else {
      request[column] = this.editValue;
    }
    if (field.type === FieldType.boolean) {
      request[column] = convertYesNoToBoolean(this.editValue);
    }
    const updateSub = this.entityDataService.updateEntityData(this.entity.id, recordId, request).subscribe(
      (result) => {
        this.loadingColumn = null;
        if (result) {
          this.onClickCancel();
          // update data and data snapshot
          const updatedData = [...this.data];
          const updatedSnapshot = [...this.dataSnapshot];
          const record = updatedData.find(item => item['Id'].toLowerCase() === result['Id'].toLowerCase());
          if (record) {
            record['UpdateTime'] = moment.utc(result['UpdateTime']).format();
            const recordSnapshot = updatedSnapshot.find(item => item['Id'].toLowerCase() === result['Id'].toLowerCase());
            recordSnapshot['UpdateTime'] = moment.utc(result['UpdateTime']).format();
            this.data = updatedData;
            this.dataSnapshot = updatedSnapshot;
            if (this.savedQueryFilters && this.savedQueryFilters.length > 0) {
              this.assembleData(this.savedQueryFilters, this.savedOperator);
            }
          }
        }
      },
      (error) => {
        const errorMsg = error.errorText as string;
        const errorNumIndex = errorMsg.search(this.errorNumPrefix);
        const errorNumEndIndex = errorMsg.indexOf(',',errorNumIndex);
        const errorNum = errorMsg.substring(errorNumIndex + this.errorNumPrefix.length, errorNumEndIndex);
        if (errorNum === '2627') {
          this.shouldDisableEditSave = true;
          this.loadingColumn = null;
          this.columnErrorText = this.translateService.get('ListData.ColumnErrorText4');
          element[column] = this.originalValue;
        } else {
          this.onApiError(error);
        }
      });

    this.subscription.add(updateSub);
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.data.forEach(row => this.selection.select(row));
  }

  public toggleColumn() {
    let status = true;
    this.acceptedColumnsSelected.options.forEach(column => {
      if (!column.selected) {
        status = false;
      }
    });
    this.areAllAcceptedColumnsSelected = status;
    if (this.searchValue) {
      this.onSearch();
    }
  }

  public toggleAllColumn() {
    if (this.areAllAcceptedColumnsSelected) {
      this.acceptedColumnsSelected.options.forEach(column => column.select());
    } else {
      this.acceptedColumnsSelected.options.forEach(column => column.deselect());
    }
    if (this.searchValue) {
      this.onSearch();
    }
  }

  public openAdvancedSearch(savedQueryRecords?: QueryRecord[], savedOperator?: number) {
    this.telemetryService.logEvent(Events.AdvancedSearchClick);
    this.searchValue = undefined;
    this.searchValueDebounced = undefined;
    const dialogRef = this.dialog.open(AdvancedSearchComponent,
      {
        width: 'auto',
        height: '100%',
        position: { top: '0', right: '0' },
        disableClose: true,
        panelClass: 'custom-sidepane',
        data: {
          searchColumns: this.searchColumns,
          queryRecords: savedQueryRecords,
          operator: savedOperator,
          choiceSetMemberMap: this.choiceSetMemberMap
        },
        autoFocus: false,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      const queryFilters = [];
      if (result) {
        this.advancedSearchFilter = undefined;
        this.telemetryService.logEvent(Events.AdvancedSearchQuerySubmitted);
        this.searchValue = undefined;
        this.savedQueryRecords = result.queryRecords;
        this.savedOperator = result.operator;
        this.savedQueryRecords.forEach((record) => {
          let value;
          if (record.fieldType === FieldType.boolean && this.conditionDoesNotHaveEmpty(record.condition)) {
            value = record.searchValue === 'Yes';
          }
          if (record.fieldType === FieldType.choiceSetMultiple &&
            (record.condition === Condition.does_not_equal || record.condition === Condition.equals)) {
            value = JSON.stringify(record.searchValue) ;
          }
          if (record.fieldType === FieldType.dateTime) {
            value = this.convertDateToUTC(record.searchValue);
          }
          queryFilters.push(
            {
              field: {
                name: record.name,
                value: value !== undefined ? value : record.searchValue,
                type: record.fieldType,
              } as Field,
              operator: ConditionToSymbolMap[record.condition]
            } as FilterFieldEntry);
        });
        this.savedQueryFilters = queryFilters;
        this.assembleData(queryFilters, this.savedOperator);
      }
    });
  }

  public getAdvancedSearchFilter(queryRecords: QueryRecord[])  {
    let result = '';
    let index = 0;
    const operatorWord = this.savedOperator === FilterLogicalOperator.and ?
      this.advancedSearchTranslationMap[AndOrKey.and] : this.advancedSearchTranslationMap[AndOrKey.or];
    for (const queryRecord of queryRecords) {
      result += `${queryRecord.name} ${this.advancedSearchTranslationMap[ConditionKey[queryRecord.condition]]}`;
      result += this.appendValue(queryRecord);
      if (index !== queryRecords.length - 1) {
        result += ` ${operatorWord} `;
      }
      index++;
    };
    this.advancedSearchFilter = result;
    return result;
  }

  public convertDateToUTC(value: any) {
    const localTimeString = value.replace('Z', '');
    const date = new Date(localTimeString);
    return date.toISOString();
  }

  public openBasicSearchWarning() {
    const dialogRef = this.dialog.open(BasicSearchComponent, {
      position: { top: '170px', left: 'calc(50% - 208px)'},
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.confirmClose) {
          this.assembleData();
          this.savedQueryRecords = undefined;
          this.savedOperator = undefined;
          this.savedQueryFilters = undefined;
          this.advancedSearchFilter = undefined;
        }
      }
    });
  }

  public copyAdvancedSearhFilterLinkToQuery() {
    this.telemetryService.logEvent(Events.AdvancedSearchQueryLinkCopied);
    const locationHref = this.getLocationHref();
    const questionMark = locationHref.indexOf('?');
    let baseUrl: string;
    if (questionMark > -1) {
      baseUrl = locationHref.substring(0, questionMark);
    } else {
      baseUrl = locationHref;
    }
    this.writeText(baseUrl);
    this.snackBar.openFromComponent(
      SnackBarComponent,
      {
        duration: 5000,
        verticalPosition: 'top',
        data: { mode: SnackBarMode.success, text: this.translateService.get('ListData.QueryLinkCopied') }
      }
    );
  }

  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Id}`;
  }

  public onChangePagination(pagination: Pagination) {
    this.telemetryService.startTimedEvent(Events.DataPageChange);
    if (this.nextPageDataCache.length > 0 && pagination.pageIndex !== 0
      && (!this.pagination || this.pagination.pageIndex < pagination.pageIndex)) {
      this.pagination = pagination;
      this.showDataForPagination(this.nextPageDataCache);
      this.telemetryService.endTimedEvent(Events.DataPageChange);
      const accessibleFields = this.userService.getAccessibleFields(this.entity, Constants.ReadEntityData);
      const expansions = this.getExpansions(accessibleFields);
      const queryFields = accessibleFields.filter(f => !f.isForeignKey);
      this.prepareNextPageData(accessibleFields, expansions, queryFields, this.savedQueryFilters, this.savedOperator);
      return;
    }
    this.pagination = pagination;
    this.assembleData(this.savedQueryFilters, this.savedOperator);
  }

  public getComparableValue(element: any, column: string): string {
    const field = this.getFieldByName(column);
    if (element[column] && [FieldType.date, FieldType.dateTime].includes(field.type)) {
      return (new Date(element[column])).toISOString();
    }

    if ([FieldType.date, FieldType.dateTime].includes(field.type)) {
      return this.getShowingValue(element, column);
    }

    if(this.isAutoNumber(column)) {
      return this.getShowingValue(element, column).toString();
    }

    return this.getShowingValue(element, column).toLocaleLowerCase();
  }

  public getShowingValueWithToolTip(element: any, column: string): Observable<string> {
    const field = this.getFieldByName(column);
    if (field.isRbacEnabled) {
      return this.userService.checkFieldPermission([this.UpdateEntityData], this.entity.id, field.id).pipe(mergeMap(result => {
        if (!result) {
          return this.translateService.get('NoPermissionEditToolTip');
        } else {
          return this.getTooltipValue(field, element, column);
        }
      }));
    }

    return this.getTooltipValue(field, element, column);
  }

  public getShowingValue(element: any, column: string, isForToolTip: boolean = false): string {
    const field = this.getFieldByName(column);
    const hasLoadedChoiceset = !!this.choiceSetMemberMap[field.choiceSetId];
    const members = this.choiceSetMemberMap[field.choiceSetId] || [];

    if (field.isForeignKey) {
      if (element[column] && element[column]['Id'] && element[column]['Id'].length > 0) {
        const foreignFieldName = field.referenceField.definition['name'];
        return this.getConvertedValue(element[column][foreignFieldName], field.referenceField.type);
      } else {
        return '-';
      }
    } else if (field.fieldDisplayType === Constants.ChoiceSetSingle && !isNullOrUndefined(element[column])) {
      if (!hasLoadedChoiceset) {
        return this.loadingText;
      }
      const member = members.find(m => m.NumberId === element[column]);
      return member && member.DisplayName || '';
    } else if (field.fieldDisplayType === Constants.ChoiceSetMultiple && !isNullOrUndefined(element[column])) {
      const memberIds: number[] = element[column];
      if (memberIds.length === 0) {
        return '-';
      }

      if (!hasLoadedChoiceset) {
        return this.loadingText;
      }

      return (element[column] as number[])
        .map(id => {
          const m = members.find(m => m.NumberId === id);
          return m && m.DisplayName || '';
        })
        .filter(v => !!v)
        .join(', ');
    } else if (field.fieldDisplayType === Constants.File && element[column]) {
      const fileInfo = element[column];
      return fileInfo['Name'];
    } else if (field.type === FieldType.boolean && ['yes', 'no'].includes(element[column])) {
      return element[column] === 'yes' ? 'Yes' : 'No';
    } else if (!element[column] && element[column] !== 0) {
      return '-';
    } else if (field.type === FieldType.dateTime) {
      if (isForToolTip) {
        if (field.isSystemField) {
          try {
            return this.getLocalizedTime(element[column]);
          }
          catch {
            return this.convertISOString(element[column]);
          }
        }
      } else if (field.isSystemField) {
        return this.convertISOtoLocalDateTime(element[column]);
      } else {
        return this.convertISOtoLocalDateTime(element[column], { timeZone: 'UTC' });
      }
    } else if (this.isWholeNumber(field, element[column])) {
      if (!!field.constraint.DecimalPrecision && field.constraint.DecimalPrecision > 0) {
        return element[column] + '.' + ('0').repeat(field.constraint.DecimalPrecision);
      }
    }
    return element[column];
  }

  public convertISOtoLocalDateTime(iso: string, options?: any): string {
    const dateObj = new Date(iso);
    return dateObj.toLocaleString(undefined, options);
  }

  public convertISOString(iso: string): string {
    const dateObj = new Date(iso);
    return dateObj.toISOString();
  }

  public convertNameToDisplayName(fieldName: string) {
    const field: Field = this.getFieldByName(fieldName);
    let getName = '';
    if (this.fieldNameMap.has(field.displayName))
    {
      getName = this.fieldNameMap.get(field.displayName);
      switch (field.displayName) {
      case 'CreatedBy':
        this.translateService.get('ListData.CreatedBy').subscribe(t => getName = t);
        break;
      case 'CreateTime':
        this.translateService.get('ListData.CreateTime').subscribe(t => getName = t);
        break;
      case 'UpdatedBy':
        this.translateService.get('ListData.UpdatedBy').subscribe(t => getName = t);
        break;
      case 'UpdateTime':
        this.translateService.get('ListData.UpdateTime').subscribe(t => getName = t);
        break;
      case 'RecordOwner':
        this.translateService.get('ListData.RecordOwner').subscribe(t => getName = t);
        break;
      default:
        break;
      }
    }
    return this.fieldNameMap.has(field.displayName) ? getName : field.displayName;
  }

  public onResizeEnd(event: ResizeEvent, columnName: string): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      if (columnElts) {
        for (let i = 0; i < columnElts.length; i++) {
          const currentEl = columnElts[i] as HTMLDivElement;
          currentEl.style.width = cssValue;
        }
      }
    }
  }

  public assembleData = (
    filterFields?: FilterFieldEntry[],
    filterLogicalOperator?: FilterLogicalOperator,
    filterGroups?: QueryFilterGroup[]
  ): void => {
    this.isLoading = true;

    const start = this.pagination && this.pagination.pageIndex || 0;
    const limit = this.pagination && this.pagination.pageSize || 20;
    const skipRows = start === 0 ? 0 : start * limit;
    const accessibleFields = this.userService.getAccessibleFields(this.entity, Constants.ReadEntityData);
    // todo nanz -- uncomment when rlp server side is done
    // accessibleFields = this.isRlpEnabled ? accessibleFields : accessibleFields.filter(fld => fld.name !== 'sys_owner');

    const expansions = this.getExpansions(accessibleFields);
    const queryFields = accessibleFields.filter(f => !f.isForeignKey);

    const getEntitySub = this.entityDataService.queryEntityData(
      this.entity, queryFields, filterFields ? filterFields : [], filterLogicalOperator,
      [], skipRows, limit, this.sortOptionCreateTime, expansions).subscribe((result) => {
      this.length = result.totalRecordCount;
      this.showDataForPagination(this.formatResultData(accessibleFields, result));
      this.prepareNextPageData(accessibleFields, expansions, queryFields, filterFields, filterLogicalOperator);

      this.telemetryService.endTimedEvent(Events.EntityDataView);
      this.telemetryService.endTimedEvent(Events.DataPageChange);
      this.isLoading = false;
      this.initialLoad = false;
    },
    (error) => {
      this.isLoading = false;
      this.initialLoad = false;

      if (error && JSON.stringify(error).indexOf('403') > -1) {
        this.showNoPermission = true;
      } else {
        this.dialog.open(ErrorDialogComponent, { data: error });
      }
    });
    this.subscription.add(getEntitySub);
    // reset edit value everytime it loads
    this.onClickCancel();
  };

  public getDataLength() {
    return this.data.length;
  }

  public shouldDisplayMustUseNumbersForNumberTypes() {
    return this.searchValue && this.onlyNumberColumnsChosen && this.isQueryBuilderEnabled;
  }

  public shouldDisplayResultsFor() {
    return this.searchValueDebounced && !this.isLoading && !this.onlyNumberColumnsChosen &&
      !this.noSelectedSearchColumns && this.isQueryBuilderEnabled;
  }

  public initializeAcceptedSearchColumns(columns: any) {
    this.selectedAcceptedSearchColumns = [];
    this.acceptedSearchColumns = [];
    this.displayNamesToNameAndSystemType = {};
    this.savedFields = [];
    this.searchColumns = [];
    columns.forEach((column) => {
      const field = this.entity.fields.find((field) => field.name === column);
      this.searchColumns.push({name: field.name, fieldType: field.type, choiceSetId: field.choiceSetId} as SearchColumn);
      this.savedFields.push({name: column, fieldType: field.type, choiceSetId: field.choiceSetId} as SearchColumn);
      this.addNestedColumnFields(field.type, field.fieldDisplayType, column);
      if (this.acceptedSearchColumnTypes.has(field.type)) {
        const displayName = field.displayName;
        this.acceptedSearchColumns.push(displayName);
        this.selectedAcceptedSearchColumns.push(displayName);
        this.displayNamesToNameAndSystemType[displayName] =
          {name: field.name, isSearchableSystemField: false} as NameWithIsSystemField;
      } else if (this.isSearchableSystemField(field)) {
        const displayName = this.convertNameToDisplayName(field.name);
        this.acceptedSearchColumns.push(displayName);
        this.selectedAcceptedSearchColumns.push(displayName);
        this.displayNamesToNameAndSystemType[displayName] =
          {name: field.name, isSearchableSystemField: true} as NameWithIsSystemField;
      }
    });
  }

  public showListToolBar() {
    return !this.savedQueryRecords && !this.initialLoad && ((this.data && this.data.length > 0) || this.searchValueDebounced);
  }

  public getAddDataToolTip(): Observable<string> {
    return this.addDataToolTip();
  }

  public getUserEntity(): Entity {
    return this.entities && this.entities.find(ent => ent.entityType === EntityType.system && ent.name === Constants.SystemUserEntity);
  }

  public getUserEntityNameField(): Field {
    const userEntity = this.getUserEntity();
    return userEntity && userEntity.fields.find(fld => fld.name === Constants.SystemUserNameField);
  }

  private loadChoiceSetMembers() {
    this.isLoadingChoiceSets = true;
    const choiceSetIds = this.entity.fields
      .filter(f => [Constants.ChoiceSetSingle, Constants.ChoiceSetMultiple].includes(f.fieldDisplayType))
      .map(f => f.choiceSetId);
    const choiceSetBatch = [];
    choiceSetIds.forEach(id => {
      choiceSetBatch.push(this.entityDataService.queryChoiceSetMembers(id).pipe(map((result) => {
        this.choiceSetMemberMap[id] = result.value as any;
      })));
    });
    forkJoin(choiceSetBatch).subscribe(() => {
      this.isLoadingChoiceSets = false;
    });

    this.translateService.get('ListData.Loading').subscribe(t => this.loadingText = t);
  }

  private getExpansions(fields: Field[]): Expansion[] {
    const expansions: Expansion[] = [];
    fields.forEach(f => {
      if (f.isForeignKey) {
        const expansion: Expansion = {
          expandedField: f.name,
          selectedFields: this.getSelectedFields(f),
        };
        expansions.push(expansion);
      }
    });
    return expansions;
  }

  private getSelectedFields(f: Field): string[] {
    if (f.fieldDisplayType === 'File') {
      return undefined;
    } else if (this.isSearchableSystemField(f)) {
      return this.systemNestedFields.map((searchColumn) => {
        return searchColumn.name;
      });
    } else {
      return ['Id', f.referenceField.definition['name']];
    }
  }

  private isSearchableSystemField(field: Field) {
    return field.isSystemField && (field.displayName === 'CreatedBy' || field.displayName === 'UpdatedBy');
  }

  private compareCreateTimeColumn(key1: string, key2: string): number {
    const field1 = this.entity.fields.find(field => field.name === key1);
    const field2 = this.entity.fields.find(field => field.name === key2);
    return field2.createTime > field1.createTime ? -1 : 1;
  }

  private getTooltipValue(field: Field, element: any, column: string) {
    if (field.type === FieldType.dateTime) {
      return this.translateService.get('ListData.DateTimeTooltip', { value: this.getShowingValue(element, column, true) });
    } else {
      return of(this.getShowingValue(element, column, true));
    }
  }

  private convertObj(existingData: {}): Field[] {

    if (!existingData) {
      return undefined;
    }

    const keys = Object.keys(existingData);
    const { fields } = this.entity;
    const result: Field[] = [];

    for (const key of keys) {
      const tranformedField = fields.find(field => field.name === key);
      tranformedField.value = existingData[key];
      result.push(tranformedField);
    }
    return result;
  }

  private scrubFieldValue(fields: Field[]): Field[] {

    const result: Field[] = [];
    for (const field of fields) {
      field.value = null;
      result.push(field);
    }
    return result;
  }

  private getFieldExistingValue(existingData: {}, fields: Field[]): Field[] {
    const result: Field[] = [];
    for (const field of fields) {
      field.value = existingData ? existingData[field.name] : null;
      result.push(field);
    }
    return result;
  }

  private getConvertedValue(value: string, type: FieldType): string {
    switch (type) {
    case FieldType.boolean:
      return convertBooleanToYesNo(value as any);
    case FieldType.date:
      return value && value.length > 0 && moment(value).local().format('L');
    case FieldType.dateTime:
      return value && value.length > 0 && moment(value).local().format();
    }
    return value;
  }

  public getFieldByName(name: string): Field {
    return this.fieldMap[name];
  }

  public onClearValue(column: string, event: any, element: any) {
    event.stopPropagation();
    const field = this.getFieldByName(column);
    element[column] = null;
    this.editValue = "";
    this.updateEntityData(element['Id'], field, element, column);
  }

  public hasNoValue(element: any, column: string): boolean {
    const res = this.getShowingValue(element, column);
    return res === '-';
  }

  private conditionDoesNotHaveEmpty(condition: Condition) {
    return condition !== Condition.is_empty && condition !== Condition.is_not_empty;
  }

  private initializeSearchColumns() {
    this.searchColumns = [];
    this.entity.fields.forEach(field => {
      this.searchColumns.push({name: field.name, fieldType: field.type, choiceSetId: field.choiceSetId} as SearchColumn);
      this.addNestedColumnFields(field.type, field.fieldDisplayType, field.name);
    });
  }

  private buildQueryRecordsFromFilter(filterFields: FilterFieldEntry[]) {
    this.savedQueryRecords = [];
    const conditions = Object.keys(ConditionToSymbolMap);
    filterFields.forEach((filter) => {
      if ((filter.operator === '=' || filter.operator === '!=') && filter.field.value === undefined) {
        this.savedQueryRecords.push({
          name: filter.field.name,
          fieldType: filter.field.type,
          condition: filter.operator === '=' ? 'isempty' : 'isnotempty',
        } as QueryRecord);
      } else if (filter.operator === 'in' || filter.operator === 'not in') {
        this.savedQueryRecords.push({
          name: filter.field.name,
          fieldType: filter.field.type,
          condition: conditions.find(condition => ConditionToSymbolMap[condition] === filter.operator),
          searchValue: filter.field.value,
        } as QueryRecord);
      } else {
        let value: any = filter.field.value;
        if (filter.field.type === FieldType.dateTime) {
          value = formatDate(new Date(filter.field.value), 'yyyy-MM-ddTHH:mm', 'en');
        } else if (filter.field.type === FieldType.date) {
          value = new Date(filter.field.value);
        }
        this.savedQueryRecords.push({
          name: filter.field.name,
          fieldType: filter.field.type,
          condition: conditions.find(condition => ConditionToSymbolMap[condition] === filter.operator),
          searchValue: value,
        } as QueryRecord);
      }
    });
  }

  private buildAdvancedSearchTranslationMap() {
    Object.values(ConditionKey).forEach((value) => {
      this.translateService.get(value).subscribe(result => {
        this.advancedSearchTranslationMap[value] = result;
      });
    }
    );

    Object.values(AndOrKey).forEach((value) => {
      this.translateService.get(value).subscribe(result => {
        this.advancedSearchTranslationMap[value] = result;
      });
    });
  }

  private resetTableView() {
    this.savedQueryFilters = [];
    this.searchValue = undefined;
    this.searchValueDebounced = undefined;
  }

  private getQueryFilters() {
    const queryFilters = [] as FilterFieldEntry[];
    this.selectedAcceptedSearchColumns.forEach((column) => {
      const fieldType = this.searchColumns.find((searchColumn) =>
        searchColumn.name === this.displayNamesToNameAndSystemType[column].name).fieldType;
      if ((fieldType !== FieldType.autonumber && fieldType !== FieldType.number) || numberValidatorWithoutControl(this.searchValue)) {
        const name = this.displayNamesToNameAndSystemType[column].name;
        queryFilters.push(
          {
            field: {
              name: this.displayNamesToNameAndSystemType[column].isSearchableSystemField ? name + '.Name' : name,
              value: this.searchValue,
              type: fieldType,
            } as Field,
            operator: Condition.contains,
            filteredOptionFields: []
          } as FilterFieldEntry
        );
      }
    });
    return queryFilters;
  }

  private appendValue(queryRecord: QueryRecord) {
    if (this.conditionDoesNotHaveEmpty(queryRecord.condition)) {
      if ((queryRecord.fieldType === FieldType.choiceSetMultiple || queryRecord.fieldType === FieldType.choiceSetSingle)) {
        let value = queryRecord.searchValue;
        if (queryRecord.condition === Condition.contains || queryRecord.condition === Condition.not_contains) {
          if (typeof(value) === 'string') {
            value = value.split(',').map(Number);
          }
        }
        return this.convertChoiceSetNumberToText(queryRecord.name, value);
      } else if (queryRecord.fieldType === FieldType.boolean) {
        if (queryRecord.searchValue === 'No' || !queryRecord.searchValue) {
          return ' No';
        } else {
          return ' Yes';
        }
      } else {
        return ` ${queryRecord.searchValue}`;
      }
    } else {
      return '';
    }
  }

  private convertChoiceSetNumberToText(name: string, searchValue: any) {
    let value = searchValue;
    if (isNumber(searchValue)) {
      value = '[' + searchValue + ']';
    }
    const choiceSetId = this.entity.fields.find(field => field.name === name).choiceSetId;
    let result = '';
    const searchValueToArray = value.constructor === Array ? value: JSON.parse(value);
    for (let i = 0; i < searchValueToArray.length; i++) {
      const value = this.choiceSetMemberMap[choiceSetId].find(choiceSetOption => choiceSetOption.NumberId === searchValueToArray[i]).Name;
      if (i === 0) {
        result += ` ${value}`;
      } else {
        result += `, ${value}`;
      }
    }
    return result;
  }

  private getLocationHref() {
    return location.href;
  }

  private writeText(baseUrl: string) {
    navigator.clipboard.writeText(baseUrl +
      '?view=data_view&query=' + encodeURI(JSON.stringify(this.entityDataService.advancedQueryRequest)));
  }

  private getLocalizedTime(element: any) {
    const utcDate = new Date(this.convertISOString(this.convertISOtoLocalDateTime(element, { timeZone: 'UTC' })));
    const localeDate = new Date(this.convertISOString(this.convertISOtoLocalDateTime(element)));
    if (utcDate.getTime() - localeDate.getTime() > 0) {
      if (utcDate.getHours() < localeDate.getHours()) {
        localeDate.setHours(localeDate.getHours() - (24 - localeDate.getHours() + utcDate.getHours()));
      } else {
        localeDate.setHours(localeDate.getHours() - (utcDate.getHours() - localeDate.getHours()));
      }
    } else {
      if (utcDate.getHours() > localeDate.getHours()) {
        localeDate.setHours(localeDate.getHours() + (24 - utcDate.getHours() + localeDate.getHours()));
      } else {
        localeDate.setHours(localeDate.getHours() + (localeDate.getHours() - utcDate.getHours()));
      }
    }
    return this.convertISOString(localeDate.toISOString());
  }

  private onApiError = (error: any) => {
    this.loadingColumn = null;
    this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, verticalPosition: 'top', data: error });
  };

  private isSystemField(column: any): boolean {
    if (column === 'Id' || column === 'CreateTime' || column === 'CreatedBy' || column === 'UpdateTime' || column === 'UpdatedBy'
        || column === 'RecordOwner'
    ) {
      return true;
    }
    return false;
  }

  private isWholeNumber(field: Field, value: any): boolean {
    if (field.type !== FieldType.number || isNullOrUndefined(value)) {
      return false;
    }
    const valueToString = String(value);
    return !valueToString.includes('.');
  }

  private prepareNextPageData = (accessibleFields = [], expansions = [], queryFields = [],
    filterFields?: FilterFieldEntry[], filterLogicalOperator?: FilterLogicalOperator, filterGroups?: QueryFilterGroup[]): void => {

    this.nextPageDataCache = [];
    const start = this.pagination && this.pagination.pageIndex + 1 || 1;
    const limit = this.pagination && this.pagination.pageSize || 20;
    const skipRows = start * limit;

    if (skipRows >= this.length) {
      return;
    }

    if (accessibleFields.length === 0 || expansions.length === 0 || queryFields.length === 0) {
      accessibleFields = this.userService.getAccessibleFields(this.entity, Constants.ReadEntityData);
      expansions = this.getExpansions(accessibleFields);
      queryFields = accessibleFields.filter(f => !f.isForeignKey);
    }

    const getEntitySub = this.entityDataService.queryEntityData(
      this.entity, queryFields, filterFields ? filterFields : [], filterLogicalOperator ? filterLogicalOperator : null,
      [], skipRows, limit, this.sortOptionCreateTime, expansions).subscribe((result) => {
      this.length = result.totalRecordCount;
      this.nextPageDataCache = this.formatResultData(accessibleFields, result);

    },
    (error) => {
      this.telemetryService.logError(
        { type: ErrorType.warning, message: `list-data prepareNextPageData query-expansion failed ${JSON.stringify(error)}` });
    });
    this.subscription.add(getEntitySub);
  };

  private formatResultData(accessibleFields: Field[], result: any) {
    this.fieldMap = {};
    for (const field of accessibleFields) {
      // update true false to yes/no
      if (field.type === FieldType.boolean) {
        for (const entry of result.value) {
          entry[field.name] = convertBooleanToYesNo(entry[field.name] as any);
        }
      } else if (field.type === FieldType.date) {
        for (const entry of result.value) {
          entry[field.name] = entry[field.name] && moment(entry[field.name]).format('L');
        }
      }
      this.fieldMap[field.name] = field;
    }
    return result.value;
  }

  private showDataForPagination(data: any[]) {
    // show data based on pagination value..
    if (this.pagination && this.pagination.pageIndex !== 0 && data.length === 0) {
      this.pagination.pageIndex = 0;
      this.assembleData();
      return;
    }
    this.data = data;
    this.dataSnapshot = [...this.data];
    this.columns = this.data && this.data.length > 0 && Object.keys(this.fieldMap);
    if (this.columns) {
      this.columns.sort((a: string, b: string) => this.compareCreateTimeColumn(a, b));

      const lookupColumns = [...this.columns];

      for (const column of lookupColumns) {
        const field = this.entity.fields.find(field => field.name === column);
        if (field.name === 'Id') {
          // move system field to the first
          this.columns.unshift(this.columns.splice(this.columns.indexOf(column), 1)[0]);
        } else if (field.isSystemField && field.name !== 'Id') {
          // move system field to last
          this.columns.push(this.columns.splice(this.columns.indexOf(column), 1)[0]);
        }
      }

      // add select column
      if (!this.isSystemEntity(this.entity) && Array.isArray(this.columns) && !this.columns.find(itm => itm === 'select')) {
        this.columns.unshift('select');
      }

      if (this.columns.slice(1).length !== this.savedFields.length) {
        this.initializeAcceptedSearchColumns(this.columns.slice(1));
      }
    }
    this.selection.clear();
  }

  private addNestedColumnFields(fieldType: FieldType, fieldDisplayType: string, column: string) {
    if (fieldType === FieldType.relationship) {
      if (fieldDisplayType === 'File') {
        this.fileNestedFields.forEach((field) => {
          this.searchColumns.push({name: column + '.' + field.name, fieldType: field.fieldType});
        });
      }
      else if (this.entity.fields.find(field => field.name === column).isSystemField) {
        this.systemNestedFields.forEach((field) => {
          this.searchColumns.push({name: column + '.' + field.name, fieldType: field.fieldType});
        });
      } else {
        const referenceField = this.entity.fields.find(field => field.name === column).referenceField;
        this.searchColumns.push({name: column + '.' + referenceField.definition['name'], fieldType: referenceField.type});
      }
    }
  }
}
