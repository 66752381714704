<div fxLayout="row" fxLayoutAlign="start stretch" class="manage-access">
  <h1 *ngIf="data.mode === 'Update'" mat-dialog-title class="bold font-large">
    <app-breadcrumb-secondary [breadcrumbs]="breadcrumbs"></app-breadcrumb-secondary>
    {{'CustomRoleDialog.UpdateRole' | translate}}
  </h1>
  <h1 *ngIf="data.mode === 'Create'" mat-dialog-title class="bold font-large createrole">
    <app-breadcrumb-secondary [breadcrumbs]="breadcrumbs"></app-breadcrumb-secondary>
    {{'CustomRoleDialog.CreateRole' | translate}}
  </h1>
</div>
<div mat-dialog-content class="side-pane-container">
  <form [formGroup]="fieldForm" [style.fontSize.px]="14" (submit)="onSubmit()">
    <mat-form-field floatLabel="always" class="name">
      <mat-label> {{'CustomRoleDialog.RoleName' | translate}} </mat-label>
      <input matInput id="name" name="name" required formControlName="name" />
      <mat-error *ngIf="fieldForm.get('name').errors">
        {{'CustomRoleDialog.RoleNameLimitation' | translate}}
      </mat-error>
    </mat-form-field>
    <div class="label">
      {{'CustomRoleDialog.AdministrativePermissions' | translate}}
    </div>
    <div fxLayout="row" class="manage-roles admin-permission">
      <mat-checkbox name="isManageRoles" formControlName="isManageRoles">
        {{'CustomRoleDialog.ManageRoles' | translate}}
      </mat-checkbox>
      <mat-icon [matTooltip]="'ViewSystemRole.ManagePermissionTooltip' | translate" class="info-icon">
        info_outline
      </mat-icon>
    </div>
    <div fxLayout="row" class="view-schema admin-permission">
      <mat-checkbox name="isViewSchema" formControlName="isViewSchema" (change)="onCheckViewSchema($event)">
        {{'CustomRoleDialog.ViewSchema' | translate}}
      </mat-checkbox>
      <mat-icon [matTooltip]="'ViewSystemRole.ViewSchemaTooltip' | translate" class="info-icon">
        info_outline
      </mat-icon>
    </div>
    <div fxLayout="row" class="customize-schema admin-permission">
      <mat-checkbox name="isCustomizeSchema" formControlName="isCustomizeSchema"
        (change)="onCheckCustomizeSchema($event)">
        {{'CustomRoleDialog.CustomizeSchema' | translate}}
      </mat-checkbox>
      <mat-icon [matTooltip]="'ViewSystemRole.CustomizeSchemaTooltip' | translate" class="info-icon">
        info_outline
      </mat-icon>
    </div>
    <div class="label data-access-permission"> {{'CustomRoleDialog.DataAccessPermission' | translate}} </div>
    <div class="grid-head" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="name-column"></div>
      <div class="grid-column check-column" fxLayout="column" fxLayoutAlign="start start">
        <div class="check-text" [matTooltip]="'CreateText' | translate">
          {{'CreateText' | translate}}
        </div>
      </div>
      <div class="grid-column check-column" fxLayout="column" fxLayoutAlign="start start">
        <div class="check-text" [matTooltip]="'ReadText' | translate">
          {{'ReadText' | translate}}
        </div>
      </div>
      <div class="grid-column check-column" fxLayout="column" fxLayoutAlign="start start">
        <div class="check-text" [matTooltip]="'EditText' | translate">
          {{'EditText' | translate}}
        </div>
      </div>
      <div class="grid-column check-column" fxLayout="column" fxLayoutAlign="start start">
        <div class="check-text" [matTooltip]="'DeleteText' | translate">
          {{'DeleteText' | translate}}
        </div>
      </div>
      <div class="delete-placeholder"></div>
    </div>
    <div class="row-divider"></div>
    <ng-container *ngFor="let entry of entities">
      <div class="grid-row-wrapper">
        <div *ngIf="!isRlpEnabled || entry.recordLevelEntityGridEntries.length === 0" class="grid-row" fxLayout="row"
          fxLayoutAlign="space-between center">
          <div class="name-column" [matTooltip]="entry.displayName" matTooltipPosition="above">
            <span class="expand-icon" *ngIf="entry.fields && entry.fields.length>0">
              <mat-icon class="material-icons md-20">expand_more</mat-icon>
            </span>
            {{entry.displayName}}
          </div>
          <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start start">
            <mat-select [(value)]="entry.create" (selectionChange)="onCheckCreate(entry, $event)"
              class="no-border-select">
              <mat-option [value]="true">
                <span class="option-text"> {{ 'CustomRoleDialog.CanCreate' | translate }} </span>
              </mat-option>
              <mat-option [value]="false">
                <span class="option-text"> {{ 'CustomRoleDialog.CannotCreate' | translate }} </span>
              </mat-option>
            </mat-select>
          </div>
          <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start start">
            <mat-select [(value)]="entry.read" (selectionChange)="onCheckRead(entry, $event)" class="no-border-select">
              <mat-option [value]="0">
                <span class="option-text"> {{ 'CannotRead' | translate }} </span>
              </mat-option>
              <ng-container *ngIf="entry.isRlpEnabled">
                <mat-option [value]="2">
                  <span class="option-text"> {{ 'ReadOwn' | translate }} </span>
                </mat-option>
              </ng-container>
              <mat-option [value]="1">
                <span class="option-text"> {{ 'ReadAll' | translate }} </span>
              </mat-option>
            </mat-select>
          </div>
          <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start start">
            <mat-select [(value)]="entry.edit" (selectionChange)="onCheckEdit(entry, $event)" class="no-border-select">
              <mat-option [value]="0" [disabled]="isPermissionOptionDisabled(Constants.CannotEdit, entry.read)">
                <span class="option-text"> {{ 'CannotEdit' | translate }} </span>
              </mat-option>
              <ng-container *ngIf="entry.isRlpEnabled">
                <mat-option [value]="2" [disabled]="isPermissionOptionDisabled(Constants.EditOwn, entry.read)">
                  <span class="option-text"> {{ 'EditOwn' | translate }} </span>
                </mat-option>
              </ng-container>
              <mat-option [value]="1" [disabled]="isPermissionOptionDisabled(Constants.EditAll, entry.read)">
                <span class="option-text"> {{'EditAll' | translate }} </span>
              </mat-option>
            </mat-select>
          </div>
          <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start start">
            <mat-select [(value)]="entry.delete" (selectionChange)="onCheckDelete(entry, $event)"
              class="no-border-select">
              <mat-option [value]="0" [disabled]="isPermissionOptionDisabled(Constants.CannotDelete, entry.read)">
                <span class="option-text"> {{ 'CannotDelete' | translate }} </span>
              </mat-option>
              <ng-container *ngIf="entry.isRlpEnabled">
                <mat-option [value]="2" [disabled]="isPermissionOptionDisabled(Constants.DeleteOwn, entry.read)">
                  <span class="option-text"> {{ 'DeleteOwn' | translate }} </span>
                </mat-option>
              </ng-container>
              <mat-option [value]="1" [disabled]="isPermissionOptionDisabled(Constants.DeleteAll, entry.read)">
                <span class="option-text"> {{ 'DeleteAll' | translate }} </span>
              </mat-option>
            </mat-select>
          </div>
          <div class="delete-check check-column-position">
            <button class="delete" mat-icon-button [matTooltip]="'CustomRoleDialog.Delete' | translate"
              (click)="onDeleteEntry(entry)" type="button">
              <mat-icon class="delete-icon">delete_outline</mat-icon>
            </button>
          </div>
        </div>
        <!-- Record level permission enabled Entity entry -->
        <ng-container *ngIf="isRlpEnabled && entry.recordLevelEntityGridEntries.length > 0">
          <div *ngFor="let rlpEntry of entry.recordLevelEntityGridEntries; let i = index" class="grid-row"
            fxLayout="row" fxLayoutAlign="space-between center">
            <div class="name-column"> {{ i === 0 ? rlpEntry.displayName : '' }} </div>
            <div class="access-column grid-column bold font-semi-small">
              {{ i === 0 ? ('OwnedByUserText' | translate) : ('NotOwnedByUserText' | translate) }}
            </div>
            <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start center">
              <mat-checkbox *ngIf="i === 0" class="check-box" [checked]="rlpEntry.create"
                (change)="onCheckCreate(rlpEntry, $event, i)">
              </mat-checkbox>
            </div>
            <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start center">
              <mat-checkbox class="check-box" [checked]="rlpEntry.read" (change)="onCheckRead(rlpEntry, $event, i)">
              </mat-checkbox>
            </div>
            <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start center">
              <mat-checkbox class="check-box" [checked]="rlpEntry.edit"
                (change)="onCheckEdit(rlpEntry, $event, i)"></mat-checkbox>
            </div>
            <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start center">
              <mat-checkbox class="check-box" [checked]="rlpEntry.delete" (change)="onCheckDelete(rlpEntry, $event, i)">
              </mat-checkbox>
            </div>
            <div *ngIf="i===0" class="delete-check check-column-position">
              <button class="delete" mat-icon-button [matTooltip]="'CustomRoleDialog.Delete' | translate"
                (click)="onDeleteEntry(entry)" type="button">
                <mat-icon class="md-18 delete-icon">delete_outline</mat-icon>
              </button>
            </div>
            <div *ngIf="i!==0" class="delete-check-placeholder">
            </div>
          </div>
        </ng-container>

        <!-- column level security goes here -->
        <ng-container *ngIf="entry.isRbacEnabled && isRbacEnabled">
          <div *ngFor="let fieldEntry of entry.fields" class="grid-row field-row" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div class="name-field-column">{{fieldEntry.field.fieldDisplayName}}</div>
            <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start start">
              <mat-checkbox class="check-box" [checked]="fieldEntry.create"
                [disabled]="!entry.create && !isRlpEntriesCreateAllTrue(entry)"
                (change)="onCheckFieldCreate(entry, fieldEntry, $event)">
              </mat-checkbox>
            </div>
            <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start start">
              <mat-checkbox class="check-box" [checked]="fieldEntry.read"
                [disabled]="!entry.read && !isRlpEntriesReadAllTrue(entry)"
                (change)="onCheckFieldRead(entry, fieldEntry, $event)">
              </mat-checkbox>
            </div>
            <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start start">
              <mat-checkbox class="check-box" [checked]="fieldEntry.edit"
                [disabled]="!entry.edit && !isRlpEntriesEditAllTrue(entry)"
                (change)="onCheckFieldEdit(entry, fieldEntry, $event)">
              </mat-checkbox>
            </div>
            <div class="check-column grid-column check-column-position" fxLayout="column" fxLayoutAlign="start center">
            </div>
            <div class="delete-check-field check-column-position">
              <button class="delete" mat-icon-button [matTooltip]="'CustomRoleDialog.Delete' | translate"
                (click)="onDeleteFieldEntry(entry, fieldEntry)" type="button">
                <mat-icon class="delete-icon">delete_outline</mat-icon>
              </button>
            </div>
          </div>
          <button [id]="getAddFieldId(entry)"
            *ngIf="entry.fields && entry.fields.length>0 && entry.fields.length !== getFieldsLength(entry)" mat-button
            [attr.aria-label]="'CustomRoleDialog.AddField' | translate" class="tool-bar-btn bold add-field-btn"
            color="primary" type="button" (click)="onClickAddField(entry, 0)">
            <span style="display: flex; align-items: center;">
              <mat-icon class="material-icons md-20">add</mat-icon>
              {{'CustomRoleDialog.AddField' | translate}}
              <mat-icon class="material-icons md-20">expand_more</mat-icon>
            </span>
          </button>
          <div *ngIf="!entry.fields || entry.fields.length===0" fxLayout="row" fxLayoutAlign="start none"
            class="font-semi-small first-add-field">
            <div>
              {{'CustomRoleDialog.AddFieldText' | translate}}
            </div>
            <div>
              <a [id]="getAddFieldId(entry)" class="add-them"
                (click)="onClickAddAllFields(entry)">{{'CustomRoleDialog.AddThemText' |
                translate}}</a>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <button id="addEntity" mat-button [attr.aria-label]="'CustomRoleDialog.AddEntity' | translate"
      class="tool-bar-btn bold" color="primary" type="button" (click)="onClickAddEntity()" fxLayout="row"
      fxLayoutAlign="space-between center">
      <span style="display: flex; align-items: center;">
        <mat-icon class="material-icons md-20">add</mat-icon>
        {{'CustomRoleDialog.AddEntity' | translate}}
        <mat-icon class="material-icons md-20">expand_more</mat-icon>
      </span>
    </button>
    <div fxLayout="row" fxLayoutAlign="end stretch" class="button-section bottom-btn">
      <button mat-stroked-button color="primary" class="btn bold cancel" (click)="onDismiss()" type="button">
        {{'CancelText' | translate}}
      </button>
      <button *ngIf="!isSaving" mat-flat-button color="primary" type="submit" class="btn bold"
        [disabled]="isSubmitDisabled()">
        {{'SaveText' | translate}}
      </button>
      <div class="btn" *ngIf="isSaving">
        <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="24" class="spinner"></mat-spinner>
      </div>
    </div>
  </form>
</div>
