<div fxLayout="row" fxLayoutAlign="start strech" class="canvas">
  <ap-shell active="dataservice" [productName]="'AppTitle' | translate" [showSlotEndDivider]="true"
    (logoClicked)="direcToHome($event)">
    <div slot="slot-end" class="more-container" fxLayoutAlign="center center">
      <ng-container *ngIf="isCdmEnabled">
        <button mat-button class="template-button" (click)="onClickTemplates()">
          <mat-icon class="icon-margin">description</mat-icon>
          {{'AppRoutes.Templates' | translate}}
        </button>
      </ng-container>
      <ng-container *ngIf="isAdminConsistencyEnabled">
        <button mat-icon-button [matTooltip]="'AppRoutes.ApiAccess' | translate"
          [attr.aria-label]="'AppRoutes.ApiAccess' | translate" (click)="onClickApiAccess()" class="header-button">
          <app-misc-icon [miscIconType]="'apiAccess'"></app-misc-icon>
        </button>
        <button mat-icon-button [matTooltip]="'AppRoutes.UserManagement' | translate"
          [attr.aria-label]="'AppRoutes.UserManagement' | translate" (click)="onClickUserManagement()"
          [class.disabled]="(userService.checkPermission([ManagePermission]) | async) === false" class="header-button">
          <app-misc-icon [miscIconType]="'admin'"></app-misc-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="!isAdminConsistencyEnabled">
        <button mat-icon-button [matMenuTriggerFor]="menu" [matTooltip]="'MoreTooltip' | translate"
          [attr.aria-label]="'MoreTooltip' | translate" class="more">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngIf="isChoiceSetVisible() | async">
            <button mat-menu-item class="menu-item" (click)="onClickChoicesets()">
              <div class="menu-button-text">{{'AppRoutes.ChoiceSets' | translate}}</div>
            </button>
          </ng-container>
          <div
            [matTooltip]="(userService.checkPermission([ManagePermission]) | async) === false? ('NoPermissionToolTip' | translate) : ''">
            <button mat-menu-item class="menu-item" (click)="onClickUserManagement()"
              [class.disabled]="(userService.checkPermission([ManagePermission]) | async) === false">
              <div class="menu-button-text">{{'AppRoutes.UserManagement' | translate}}</div>
            </button>
          </div>
          <ng-container *ngIf="isApiAccessVisible() | async">
            <button mat-menu-item class="menu-item" (click)="onClickApiAccess()">
              <div class="menu-button-text">{{'AppRoutes.ApiAccess' | translate}}</div>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </div>
    <portal-alert-bar *ngIf="firstRun" status="info" cancelable="true" (alertDismissed)="onDismissFirstRunMessageBar()">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="font-medium bold">{{'DefaultRoleText' | translate}}</div>
        <div class="font-medium bold check-out" (click)="onClickUserManagement(); onDismissFirstRunMessageBar()">
          {{'CheckThemOutButtonText' | translate}}
        </div>
      </div>
    </portal-alert-bar>
    <div class="main">
      <router-outlet></router-outlet>
    </div>  
  </ap-shell>
</div>
