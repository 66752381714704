import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountService } from 'src/app/shared/services/account.service';
import { AuthService } from 'src/app/shared/guards/auth.service';
import { FeatureKey, FeatureService } from 'src/app/shared/services/feature.service';
import { Subscription } from 'rxjs';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  GetRolePermissionResponse,
  CustomizeRoleRequest,
  EntityDataPermission,
  FieldPermission,
  GetFieldDataPermissionResponse,
  RoleType,
  GetRoleResponse
} from 'src/app/shared/models/user';
import { SearchEntityResponse } from 'src/app/shared/models/entity';
import { Field } from 'src/app/shared/models/field';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityPickerComponent } from 'src/app/shared/components/entity-picker/entity-picker.component';
import { MatDialog } from '@angular/material/dialog';
import { Breadcrumb } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { UrlUtils } from 'src/app/shared/url.utils';
import { FieldPickerComponent } from 'src/app/shared/components/field-picker/field-picker.component';
import { Constants } from 'src/app/shared/constants';

export enum DataMode {
  create = 'Create',
  update = 'Update'
}

export interface CustomRoleDialogData {
  mode: DataMode;
  role: GetRolePermissionResponse;
}

export interface FieldGridEntry {
  field: GetFieldDataPermissionResponse;
  create: boolean;
  read: boolean;
  edit: boolean;
  delete?: boolean;
}

export interface EntityGridEntry {
  entityId: string;
  displayName: string;
  create: boolean;
  read: number;
  edit: number;
  delete: number;
  fields?: FieldGridEntry[];
  isRbacEnabled?: boolean;
  isRlpEnabled?: boolean;
  addFieldTextClicked?: boolean;
  recordLevelEntityGridEntries?: EntityGridEntry[];
}

@Component({
  selector: 'app-create-role-page',
  templateUrl: './create-role-page.component.html',
  styleUrls: ['./create-role-page.component.scss']
})
export class CreateRolePageComponent implements OnDestroy, OnInit {
  public fieldsLengthMap: { [entityId: string]: number } = {};
  shouldShowCheck(arg0: number, arg1: GetRoleResponse) {
    throw new Error('Method not implemented.');
  }
  fieldForm: UntypedFormGroup;
  isManageRolesChecked: boolean;
  isViewSchemaChecked: boolean;
  isCustomizeSchemaChecked: boolean;
  entities: EntityGridEntry[] = [];
  entityList: SearchEntityResponse[] = null;
  fullEntityList: SearchEntityResponse[] = null;
  isSaving = false;
  allCreate = false;
  allRead = false;
  allEdit = false;
  allDelete = false;
  isLoading = false;
  isRbacEnabled = false;
  isRlpEnabled = false;
  snapshot: any;
  entityGridSnapshot: any;
  dialogWidth = 538;
  ManagePermissionId = 0;
  ReadEntitySchemeId = 1;
  UpdateEntitySchemeId = 2;
  CreateEntityDataId = 3;
  ReadEntityDataId = 4;
  UpdateEntityDataId = 5;
  DeleteEntityDataId = 6;
  subscription = new Subscription();
  breadcrumbs: Breadcrumb[];
  data: CustomRoleDialogData;
  public ReadOwnDataId = 7; // Ensure this value matches the actual ID used in your backend
  EntityDataRbacOwnLevels = 1;
  Constants = Constants;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public userService: UserService,
    public accountService: AccountService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private featureService: FeatureService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const mode = params['mode'] as DataMode;
      const roleId = params['roleId'];

      if (mode === DataMode.update && roleId) {
        this.userService.getRoleByIdAsync(roleId).subscribe((role: GetRolePermissionResponse) => {
          this.data = { mode: DataMode.update, role };
          this.initializeForm();
        });
      } else {
        this.data = { mode: DataMode.create, role: new GetRolePermissionResponse('', '', RoleType.UserDefined, [], []) };
        this.initializeForm();
      }

      const breadcrumbKey = mode === DataMode.update ? 'CustomRoleDialog.UpdateRole' : 'CustomRoleDialog.CreateRole';

      this.breadcrumbs = [
        { key: 'AppRoutes.UserManagement', url: `${UrlUtils.getBaseRouteUrl()}/users` },
        { key: breadcrumbKey }
      ];
    });
  }

  initializeForm() {
    this.isManageRolesChecked = this.data.mode === DataMode.update && this.data.role.adminPermissions.includes(this.ManagePermissionId);
    this.isViewSchemaChecked = this.data.mode === DataMode.update && this.data.role.adminPermissions.includes(this.ReadEntitySchemeId);
    const isUpdateMode = this.data.mode === DataMode.update;
    const hasUpdateEntitySchemePermission = this.data.role.adminPermissions.includes(this.UpdateEntitySchemeId);
    this.isCustomizeSchemaChecked = isUpdateMode && hasUpdateEntitySchemePermission;

    this.fieldForm = this.fb.group({
      name: [this.data.role.name, Validators.required],
      isManageRoles: [this.isManageRolesChecked],
      isViewSchema: [this.isViewSchemaChecked],
      isCustomizeSchema: [this.isCustomizeSchemaChecked]
    });

    this.entities = this.convertDataPermissionToEntityGrid().sort((a, b) => a.displayName.localeCompare(b.displayName));
    this.snapshot = JSON.stringify({ ...this.fieldForm.value });
    this.entityGridSnapshot = JSON.stringify(this.entities);

    this.featureService.isEnabled(FeatureKey.EntityColumnPermission).subscribe(result => this.isRbacEnabled = result);
    this.featureService.isEnabled(FeatureKey.EntityDataRecordPermission).subscribe(result => this.isRlpEnabled = result);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickAddEntity() {
    this.openEntityPickerDialog(this.entityList);
  }

  public openEntityPickerDialog(entities: SearchEntityResponse[]) {
    const ele = document.getElementById('addEntity');
    if (!ele) {
      return;
    }

    const rect = ele.getBoundingClientRect();
    const left = rect.left;
    const top = rect.bottom;

    const dialogRef = this.dialog.open(EntityPickerComponent, {
      panelClass: 'custom-dialog-container',
      backdropClass: 'background-none',
      data: {
        offsetX: left,
        offsetY: top,
        entities,
        selectedEntityIds: this.entities.map(itm => itm.entityId)
      },
      position: { left: `${left}px`, top: `${top}px` }
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(output => {
      if (output) {
        this.resetAllCheck();
        this.entityList = output.entities;
        this.fullEntityList = this.fullEntityList || [...output.entities];
        this.onChooseEntity(output.selected);
      }
    }));
  }

  onChooseEntity(entity: SearchEntityResponse) {
    if (!this.entities.find(itm => itm.entityId === entity.id)) {
      const newEntity: EntityGridEntry = {
        entityId: entity.id,
        displayName: entity.displayName,
        create: false,
        read: entity.isRecordLevelPermissionEnabled ? 0 : 1,
        edit: 0,
        delete: 0,
        isRlpEnabled: entity.isRbacEnabled,
        isRbacEnabled: entity.fields && entity.fields.length > 0,
        recordLevelEntityGridEntries: []
      };
      this.entities.push(newEntity);

      if (entity.referenceEntityIds) {
        entity.referenceEntityIds.forEach(entityId => {
          const entityToAdd = this.entityList?.find(itm => itm.id === entityId);
          if (entityToAdd) {
            this.onChooseEntity(entityToAdd);
          }
        });
      }
      this.syncAllReadCheckMark();
    }
  }

  syncAllReadCheckMark() {
    this.allRead = !this.entities.find(ent => !ent.read && !this.isRlpEntriesReadAllTrue(ent));
  }

  onDeleteEntry(entry: EntityGridEntry) {
    this.entities = this.entities.filter(itm => itm.entityId !== entry.entityId);
    this.entityList = null;
    if (!this.entities.length) {this.resetAllCheck();}
  }

  onDeleteFieldEntry(entry: EntityGridEntry, field: FieldGridEntry) {
    const entity = this.entities.find(itm => itm.entityId === entry.entityId);
    entity.fields = entity.fields.filter(fld => fld.field.fieldId !== field.field.fieldId);
    this.entities = [...this.entities];
  }

  onDismiss() {
    this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/users`]);  // Change to the appropriate route
  }

  onCheckCreate(input: EntityGridEntry, event: any, index?: number) {
    input.create = event.value;
    if (index !== undefined) {
      input.recordLevelEntityGridEntries[index].create = event.value;
    } else {
      input.create = event.value;
    }
    this.allCreate = this.entities.every(entry => this.isRlpEntriesCreateAllTrue(entry) || entry.create);
  }

  onCheckFieldCreate(input: EntityGridEntry, fieldEntry: FieldGridEntry, event: any) {
    const entity = this.entities.find(ent => ent.entityId === input.entityId);
    const field = entity.fields.find(fld => fld.field.fieldId === fieldEntry.field.fieldId);
    field.create = event.checked;
    if (!event.checked) {field.edit = false;}
  }

  onCheckFieldEdit(input: EntityGridEntry, fieldEntry: FieldGridEntry, event: any) {
    const entity = this.entities.find(ent => ent.entityId === input.entityId);
    const field = entity.fields.find(fld => fld.field.fieldId === fieldEntry.field.fieldId);
    field.edit = event.checked;
    if (event.checked) {
      if (this.hasRlpEntries(entity)) {entity.recordLevelEntityGridEntries[0].create = event.checked;}
      else {entity.create = event.checked;}
      field.read = event.checked;
    }
  }

  onCheckFieldRead(input: EntityGridEntry, fieldEntry: FieldGridEntry, event: any) {
    const entity = this.entities.find(ent => ent.entityId === input.entityId);
    const field = entity.fields.find(fld => fld.field.fieldId === fieldEntry.field.fieldId);
    field.read = event.checked;
    if (!event.checked) {field.edit = false;}
  }

  onCheckRead(input: EntityGridEntry, event: any, index?: number) {
    input.read = event.value;
    if (index !== undefined) {
      input.recordLevelEntityGridEntries[index].read = event.value;
    }
    this.allRead = this.entities.every(entry => this.isRlpEntriesReadAllTrue(entry) || entry.read === 1);
    this.allEdit = this.allRead && this.entities.every(entry => this.isRlpEntriesEditAllTrue(entry) || entry.edit);
  }

  onCheckEdit(input: EntityGridEntry, event: any, index?: number) {
    input.edit = event.value;
    if (index !== undefined) {
      input.recordLevelEntityGridEntries[index].edit = event.value;
    }
    this.allEdit = this.entities.every(entry => this.isRlpEntriesEditAllTrue(entry) || entry.edit);
    this.allRead = this.allEdit && this.entities.every(entry => this.isRlpEntriesReadAllTrue(entry) || entry.read);
  }

  onCheckCustomizeSchema(event: any) {
    if (event.checked) {
      this.isViewSchemaChecked = event.checked;
      this.fieldForm.patchValue({ isViewSchema: this.isViewSchemaChecked });
    }
  }

  onCheckViewSchema(event: any) {
    if (!event.checked) {
      this.isCustomizeSchemaChecked = false;
      this.fieldForm.patchValue({ isCustomizeSchema: this.isCustomizeSchemaChecked });
    }
  }

  onCheckDelete(input: EntityGridEntry, event: any, index?: number) {
    input.delete = event.value;
    if (index !== undefined) {
      input.recordLevelEntityGridEntries[index].delete = event.value;
    }
    this.allDelete = this.entities.every(entry => this.isRlpEntriesDeleteAllTrue(entry) || entry.delete);
    this.syncAllReadCheckMark();
  }

  updatePermission(input: EntityGridEntry, event: any, permission: string, index?: number) {
    this.entities.forEach(entry => {
      if (entry.entityId === input.entityId) {
        if (index !== undefined) {
          entry.recordLevelEntityGridEntries[index][permission] = event.checked;
        } else {
          entry[permission] = event.checked ? 1 : 0;
          if (permission === 'edit' && event.value === 2) {
            entry[permission] = 2; // Set to 'edit own'
          }
        }

        if (!event.checked && (permission === 'read' || permission === 'edit')) {
          entry.fields?.forEach(field => field[permission] = event.checked);
        }
      }
    });
  }

  public isPermissionOptionDisabled(option: string, readPermission: number): boolean {
    if (readPermission === 0) { // Cannot Read
      return option !== Constants.CannotEdit
      && option !== Constants.CannotDelete
      && option !== Constants.CannotRead;
    } else if (readPermission === 2) { // Read Own
      return option !== Constants.EditOwn
      && option !== Constants.CannotEdit
      && option !== Constants.DeleteOwn
      && option !== Constants.CannotDelete;
    } else if (readPermission === 1) { // Read All
      return false;
    }
    return false;
  }

  isRlpEntriesCreateAllTrue(input: EntityGridEntry): boolean {
    return this.hasRlpEntries(input) && input.recordLevelEntityGridEntries[0].create;
  }

  isRlpEntriesReadAllTrue(input: EntityGridEntry): boolean {
    return this.hasRlpEntries(input) && input.recordLevelEntityGridEntries.every(entry => entry.read === 1);
  }

  isRlpEntriesEditAllTrue(input: EntityGridEntry): boolean {
    return this.hasRlpEntries(input) && input.recordLevelEntityGridEntries.every(entry => entry.edit);
  }

  isRlpEntriesDeleteAllTrue(input: EntityGridEntry): boolean {
    return this.hasRlpEntries(input) && input.recordLevelEntityGridEntries.every(entry => entry.delete);
  }

  hasRlpEntries(input: EntityGridEntry): boolean {
    return input.recordLevelEntityGridEntries?.length === 2;
  }

  onSubmit() {
    if (this.fieldForm.invalid) {
      return;
    }

    const request: CustomizeRoleRequest = {
      name: this.fieldForm.value.name,
      adminPermissions: this.convertToAdminPermissions(),
      dataPermissions: this.convertToDataPermissions(),
    };

    this.isSaving = true;
    const roleService = this.data.mode === DataMode.create
      ? this.userService.createCustomRole(request)
      : this.userService.updateCustomRole(this.data.role.id, request);

    this.subscription.add(roleService.subscribe(
      () => {
        this.accountService.getProfile().subscribe(profile => {
          this.authService.setUserProfile(profile);
          this.router.navigate([`${UrlUtils.getBaseRouteUrl()}/users`]);
        });
      }
    ));
  }

  isSubmitDisabled(): boolean {
    const current = JSON.stringify({ ...this.fieldForm.value });
    const currentGridSnapshot = JSON.stringify(this.entities);
    const isSnapshotEqual = current === this.snapshot;
    const isGridSnapshotEqual = currentGridSnapshot === this.entityGridSnapshot;
    const isFormInvalid = this.fieldForm.invalid;
    const isGridInvalid = this.isCheckGridInvalid();
    const isAdminPermissionInvalid = this.isAdminPermissionInvalid();

    return isSnapshotEqual && isGridSnapshotEqual || isFormInvalid || isGridInvalid || isAdminPermissionInvalid;
  }

  getEmptyRlpEntries(entity: SearchEntityResponse): EntityGridEntry[] {
    return [
      { entityId: entity.id, displayName: entity.displayName, create: false, delete: 0, read: 0, edit: 0 },
      { entityId: entity.id, displayName: entity.displayName, create: false, delete: 0, read: 0, edit: 0 }
    ];
  }

  isAdminPermissionInvalid(): boolean {
    return this.fieldForm.value.isCustomizeSchema && !this.fieldForm.value.isViewSchema;
  }

  isCheckGridInvalid(): boolean {
    const adminPermissions = this.convertToAdminPermissions();
    const areAdminPermissionsEmpty = adminPermissions.length === 0;
    const areEntitiesEmpty = this.entities.length === 0;
    const isEntityPermissionEmpty = this.entities.some(entity =>
      this.convertPermissionsForEntityOrField(entity).length === 0 ||
      entity.fields?.some(field =>
        this.convertPermissionsForEntityOrField(field).length === 0
      )
    );

    return areAdminPermissionsEmpty && areEntitiesEmpty || isEntityPermissionEmpty;
  }

  convertToAdminPermissions(): number[] {
    const result = [];
    if (this.fieldForm.value.isManageRoles) {
      result.push(this.ManagePermissionId);
    }
    if (this.fieldForm.value.isViewSchema) {
      result.push(this.ReadEntitySchemeId);
    }
    if (this.fieldForm.value.isCustomizeSchema) {
      result.push(this.UpdateEntitySchemeId);
    }

    return result;
  }

  convertPermissionsForEntityOrField(entity: EntityGridEntry | FieldGridEntry): number[] {
    return [
      entity.create && this.CreateEntityDataId,
      entity.read && this.ReadEntityDataId,
      entity.edit && this.UpdateEntityDataId,
      entity.delete && this.DeleteEntityDataId
    ].filter(Boolean);
  }

  convertRbacPermissionsForEntityOrField(entity: EntityGridEntry | FieldGridEntry): { [key: number]: number } {
    const rbacPermissions: { [key: number]: number } = {};
    if (entity.read === 2) {rbacPermissions[this.ReadEntityDataId] = this.EntityDataRbacOwnLevels;}

    if (entity.edit === 2) {
      rbacPermissions[this.UpdateEntityDataId] = this.EntityDataRbacOwnLevels;
    }

    if (entity.delete === 2) {
      rbacPermissions[this.DeleteEntityDataId] = this.EntityDataRbacOwnLevels;
    }

    return rbacPermissions;
  }

  convertFieldResponseToFieldEntry(fields: Field[]): FieldGridEntry[] {
    return fields.map(fld => ({
      field: new GetFieldDataPermissionResponse(fld.id, fld.name, fld.displayName, []),
      create: false,
      edit: false,
      read: false
    }));
  }

  convertToDataPermissions(): EntityDataPermission[] {
    return this.entities.map(entity => {
      const permissions = this.convertPermissionsForEntityOrField(entity);
      const recordLevelRbacPermissions = this.convertRbacPermissionsForEntityOrField(entity);

      if (entity.edit === 2) {
        recordLevelRbacPermissions[this.UpdateEntityDataId] = this.EntityDataRbacOwnLevels;
      } else if (entity.edit === 1) {
        permissions.push(this.UpdateEntityDataId);
      }

      if (entity.delete === 2) {
        recordLevelRbacPermissions[this.DeleteEntityDataId] = this.EntityDataRbacOwnLevels;
      } else if (entity.delete === 1) {
        permissions.push(this.DeleteEntityDataId);
      }

      return {
        entityId: entity.entityId,
        permissions,
        recordLevelRbacPermissions,
        fieldPermissions: this.convertFieldPermissions(entity.fields)
      };
    });
  }

  convertFieldPermissions(fields: FieldGridEntry[]): FieldPermission[] {
    return fields?.map(fld => new FieldPermission(fld.field.fieldId, this.convertPermissionsForEntityOrField(fld))) || [];
  }

  resetAllCheck() {
    this.allDelete = false;
    this.allCreate = false;
    this.allEdit = false;
    this.allRead = false;
  }

  convertDataPermissionToEntityGrid(): EntityGridEntry[] {
    return this.data.role.dataPermissions?.map(dp => {
      let readValue = 0;
      let editValue = 0;
      let deleteValue = 0;

      if (dp.recordLevelRbacPermissions.hasOwnProperty('ReadData')) {
        const readDataValue = dp.recordLevelRbacPermissions['ReadData'];
        readValue = readDataValue === 0 ? 1 : 2;
      }

      if (dp.recordLevelRbacPermissions.hasOwnProperty('UpdateData')) {
        const updateDataValue = dp.recordLevelRbacPermissions['UpdateData'];
        editValue = updateDataValue === 0 ? 1 : 2;
      } else {
        editValue = !!dp.permissions.find(perm => perm === this.UpdateEntityDataId) ? 1 : 0;
      }

      if (dp.recordLevelRbacPermissions.hasOwnProperty('DeleteData')) {
        const deleteDataValue = dp.recordLevelRbacPermissions['DeleteData'];
        deleteValue = deleteDataValue === 0 ? 1 : 2;
      } else {
        deleteValue = !!dp.permissions.find(perm => perm === this.DeleteEntityDataId) ? 1 : 0;
      }

      return {
        entityId: dp.entityId,
        displayName: dp.entityDisplayName,
        create: !!dp.permissions.find(perm => perm === this.CreateEntityDataId),
        delete: deleteValue,
        edit: editValue,
        read: readValue,
        fields: dp.fieldPermissions ? this.convertFieldPermissionsToFieldEntry(dp.fieldPermissions) : [],
        isRbacEnabled: dp.hasRbacEnabledFields,
        isRlpEnabled: dp.isRbacEnabled,
        recordLevelEntityGridEntries: []
      };
    }) || [];
  }

  convertFieldPermissionsToFieldEntry(fieldPerms: GetFieldDataPermissionResponse[]): FieldGridEntry[] {
    return fieldPerms.map(fp => ({
      field: fp,
      create: !!fp.permissions.find(perm => perm === this.CreateEntityDataId),
      edit: !!fp.permissions.find(perm => perm === this.UpdateEntityDataId),
      read: !!fp.permissions.find(perm => perm === this.ReadEntityDataId)
    }));
  }

  public getAddFieldId(entity: EntityGridEntry): string {
    return `addField-${entity.entityId}`;
  }

  public onClickAddField(entity: EntityGridEntry, leftOffset: number) {
    const ele = document.getElementById(this.getAddFieldId(entity));
    if (!ele) {
      return;
    }

    const rect = ele.getBoundingClientRect();
    const left = rect.left + leftOffset;
    const top = rect.bottom;

    const fields = this.fullEntityList &&
      this.fullEntityList.find(ent => ent.id === entity.entityId)
      && this.fullEntityList.find(ent => ent.id === entity.entityId).fields;
    const selectedFieldIds = this.entities.find(itm => itm.entityId === entity.entityId).fields
      && this.entities.find(itm => itm.entityId === entity.entityId).fields.map(fld => fld.field.fieldId) || [];
    const dialogRef = this.dialog.open(FieldPickerComponent, {
      width: 'auto',
      height: 'auto',
      backdropClass: 'background-none',
      panelClass: 'custom-dialog-container',
      data: {
        offsetX: left,
        offsetY: top,
        entityId: entity.entityId,
        fields,
        selectedFieldIds,
      },
      position: { left: `${left}px`, top: `${top}px` }
    });

    const sub = dialogRef.afterClosed().subscribe(output => {
      if (!output) {
        return;
      }
      this.fullEntityList = this.fullEntityList || [...output.entities];
      this.onChooseField(entity, output.selected);
    });
    this.subscription.add(sub);
  }

  public onClickAddAllFields(entity: EntityGridEntry) {
    const ele = document.getElementById(this.getAddFieldId(entity));
    if (!ele) {
      return;
    }

    const fields = this.fullEntityList &&
      this.fullEntityList.find(ent => ent.id === entity.entityId)
      && this.fullEntityList.find(ent => ent.id === entity.entityId).fields;

    this.onChooseAllFields(entity, fields);
  }

  public getFieldsLength(entity: EntityGridEntry): number {
    if (this.fieldsLengthMap[entity.entityId] !== undefined) {
      return this.fieldsLengthMap[entity.entityId];
    }

    const fields = this.fullEntityList &&
      this.fullEntityList.find(ent => ent.id === entity.entityId) &&
      this.fullEntityList.find(ent => ent.id === entity.entityId).fields;

    const length = fields ? fields.length : 0;
    this.fieldsLengthMap[entity.entityId] = length;
    return length;
  }

  public onChooseAllFields(entity: EntityGridEntry, fields: Field[]): void {
    const selectedFields = this.convertFieldResponseToFieldEntry(fields);
    this.entities.find(ent => ent.entityId === entity.entityId).fields = selectedFields;
  }

  public onChooseField(entity: EntityGridEntry, selectedField: Field[]): void {
    let fields = this.entities.find(ent => ent.entityId === entity.entityId).fields;
    fields = fields || [];
    const selectedFields = this.convertFieldResponseToFieldEntry(selectedField);

    this.entities.find(ent => ent.entityId === entity.entityId).fields = fields.concat(selectedFields);
  }
}