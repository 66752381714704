<button mat-button color="primary" (click)="onClickEntities()">        
  <mat-icon class="icon-margin">arrow_back</mat-icon>
  {{'Back' | translate}}
</button>

<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<ng-container *ngIf="isLoading">
  <mat-spinner role="alert" [attr.aria-label]="'Loading' | translate" diameter="48" class="spinner"></mat-spinner>
</ng-container>
<ng-container *ngIf="!isLoading">
  <ng-container *ngFor="let template of templates">
    <div 
      class="template-row table-row"
      (click)="onClickRow(template)"
      >
      <mat-icon class="template-icon material-icons md-18">{{ getTemplateIcon(template.name) }}</mat-icon>
      <div>
      <div class="bold">{{(template.name + 'Name') | translate}}</div>
      <div class="description">{{(template.name + 'Description') | translate}}</div>
    </div>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
</ng-container>
