import { DirectoryEntity, GetRolePermissionResponse, CustomizeRoleRequest } from './../models/user';
import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import {
  UserGroup, Role, GetUsersResponse, UserOrGroupRoleResponse,
  AssignUserOrGroupRoleRequest, GetRoleResponse, RevokeRolesRequest, User
} from '../models/user';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../guards/auth.service';
import { TelemetryService } from './telemetry.service';
import { Events } from '../models/events';
import { RoleEntry } from 'src/app/pages/users/upsert-user-dialog/upsert-user-dialog.component';
import { FeatureService, FeatureKey } from './feature.service';
import { Entity, EntityDataQueryResponseWithExpansion } from '../models/entity';
import { Field, FieldType } from '../models/field';
import { Constants } from '../constants';

export interface UserEntry {
  email: string;
  name?: string;
  type?: string;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private telemetryService: TelemetryService,
    private featureService: FeatureService) {
  }

  private static ADMIN = '00000000-0000-0000-0000-000000000001';
  private static DESIGNER = '00000000-0000-0000-0000-000000000002';
  private static DATA_WRITER = '00000000-0000-0000-0000-000000000003';
  private static DATA_READER = '00000000-0000-0000-0000-000000000004';
  public currentUser: UserGroup;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public isUserProfileLoaded(): boolean {
    return !!this.authService.userProfile;
  }

  public checkPermission(permissions: number[], entityId?: string, fieldId?: string): Observable<boolean> {
    // v2 logic
    const adminPermissions = this.authService.userProfile && this.authService.userProfile.adminPermissions;
    const dataPermissions = this.authService.userProfile && this.authService.userProfile.dataPermissions;

    for (const permission of permissions) {
      if (adminPermissions && adminPermissions.indexOf(permission) > -1) {
        return of(true);
      }
    }

    if (entityId) {
      const entityPermissions = dataPermissions && dataPermissions[entityId];

      for (const permission of permissions) {
        if (entityPermissions && entityPermissions.indexOf(permission) > -1) {
          return of(true);
        }
      }
    }

    return of(false);
  }

  public determineNeedUpgrade(entity: Entity): Observable<boolean> {
    const createdByField = entity?.fields.find(f => f.name === Constants.CreatedBy);
    const isOldVersion = createdByField && createdByField.fieldDisplayType === Constants.FieldDisplayTypeBasic;
    const isCreatedByFieldUniqueId = createdByField && createdByField.type === FieldType.uniqueid;

    return zip(
      this.featureService.isEnabled(FeatureKey.SystemUserEntity),
      this.checkPermission([Constants.UpdateEntityScheme], entity.id)
    ).pipe(
      map(([isEnabled, hasPermission]) => {
        return isEnabled && hasPermission && isOldVersion && isCreatedByFieldUniqueId;
      })
    );
  }

  public checkFieldPermission(permissions: number[], entityId?: string, fieldId?: string): Observable<boolean> {
    return this.featureService.isEnabled(FeatureKey.EntityColumnPermission).pipe(map(result => {
      if (!result) {
        return true;
      } else {
        const dataPermissions = this.authService.userProfile && this.authService.userProfile.entityDataPermissions;

        if (entityId) {
          const entityPermissions = dataPermissions && dataPermissions[entityId];

          for (const permission of permissions) {
            if (entityPermissions && entityPermissions.permissions.indexOf(permission) > -1) {
              if (entityPermissions.fieldPermissions) {
                if (fieldId && entityPermissions.fieldPermissions[fieldId]
                  && entityPermissions.fieldPermissions[fieldId].indexOf(permission) > -1) {
                  return true;
                } else {
                  continue;
                }
              }
              return true;
            }
          }
        }

        return false;
      }
    }));
  }

  public getAccessibleFields(entity: Entity, permission: number): Field[] {
    const dataPermissions = this.authService.userProfile && this.authService.userProfile.entityDataPermissions;

    const fieldPermissions = dataPermissions && dataPermissions[entity.id] && dataPermissions[entity.id].fieldPermissions;
    if (fieldPermissions) {
      return [...entity.fields].filter(field =>
        (field.isSystemField || !field.isRbacEnabled
          || (fieldPermissions[field.id] && fieldPermissions[field.id].indexOf(permission) > -1)
          && this.isRelationshipFieldAccessible(field, permission)));
    } else {
      return [...entity.fields].filter(field => !field.isRbacEnabled && this.isRelationshipFieldAccessible(field, permission));
    }
  }

  public searchUser(searchText: string): Observable<UserGroup[]> {
    this.telemetryService.logEvent(Events.SearchUser);

    return this.http.get<UserOrGroupRoleResponse[]>(`api/Directory/Search?startsWith=${escape(searchText)}`, this.httpOptions).pipe(
      map(result => {
        return result.map(re => this.convertToUserGroup(re));
      })
    );
  }

  public searchUserV2(searchText: string): Observable<EntityDataQueryResponseWithExpansion> {
    this.telemetryService.logEvent(Events.SearchUser);

    return this.http.get<EntityDataQueryResponseWithExpansion>(
      `api/v2/Directory/Search?startsWith=${escape(searchText)}`, this.httpOptions)
      .pipe(map(result => result = {
        totalRecordCount: result.totalRecordCount,
        jsonValue: result.jsonValue,
        value: JSON.parse(result.jsonValue),
      }));
  }

  public convertToUserGroup(response: UserOrGroupRoleResponse): UserGroup {
    return new UserGroup(response.externalId, response.name, response.email, response.type, response.objectType, false);
  }

  public getUsersAndGroups(skip?: number, top?: number): Observable<GetUsersResponse> {
    this.telemetryService.logEvent(Events.GetUsersAndGroups);
    if (top) {
      skip = skip ?? 0;
      return this.http.get<GetUsersResponse>(`api/Directory?skip=${skip}&top=${top}`, this.httpOptions);
    }
    return this.http.get<GetUsersResponse>('api/Directory', this.httpOptions);
  }

  public deleteUserOrGroup(users: UserGroup[]): Observable<boolean> {
    this.telemetryService.logEvent(Events.DeleteUserOrGroup);
    const request = new RevokeRolesRequest(users.map(user => user.id));
    return this.http.post<boolean>(`api/Directory/RevokeRole`, request, this.httpOptions);
  }

  public getRoles(): Observable<GetRoleResponse[]> {
    this.telemetryService.logEvent(Events.GetRoles);

    return this.http.get<GetRoleResponse[]>('/api/Role?stats=true', this.httpOptions);
  }

  public getRolesV2(): Observable<GetRoleResponse[]> {
    this.telemetryService.logEvent(Events.GetRolesV2);

    return this.http.get<GetRoleResponse[]>('/api/v2/Role?stats=true', this.httpOptions);
  }

  public getRoleByIdAsync(roleId: string): Observable<GetRolePermissionResponse> {
    this.telemetryService.logEvent(Events.GetRoleByIdAsync);

    return this.http.get<GetRolePermissionResponse>(`/api/v2/Role/${roleId}`, this.httpOptions);
  }

  public createCustomRole(request: CustomizeRoleRequest): Observable<string> {
    this.telemetryService.logEvent(Events.CreateCustomRole);

    return this.http.post<string>('/api/v2/Role', request, this.httpOptions);
  }

  public updateCustomRole(roleId: string, request: CustomizeRoleRequest): Observable<string> {
    this.telemetryService.logEvent(Events.UpdateCustomRole);

    return this.http.post<string>(`/api/v2/Role/${roleId}`, request, this.httpOptions);
  }

  public deleteCustomRole(roleId: string): Observable<boolean> {
    this.telemetryService.logEvent(Events.DeleteCustomRoles);

    return this.http.delete<boolean>(`/api/v2/Role/${roleId}`, this.httpOptions);
  }

  public upsertUserRole(users: UserGroup[], newRoles: RoleEntry[]): Observable<boolean> {
    this.telemetryService.logEvent(Events.UpsertUserRole);

    const roles = newRoles.map(nr => nr.id);

    const assignRoleRequest = new AssignUserOrGroupRoleRequest(
      users.map(user => new DirectoryEntity(user.id, user.type, user.resolved)),
      roles
    );

    return this.http.post<boolean>(
      `api/Directory/Role`,
      assignRoleRequest,
      this.httpOptions
    );
  }

  public upsertUserRoleWithPeoplePick(users: any[], newRoles: RoleEntry[]): Observable<boolean> {
    this.telemetryService.logEvent(Events.UpsertUserRole);

    const roles = newRoles.map(nr => nr.id);

    const assignRoleRequest = new AssignUserOrGroupRoleRequest(
      users.map(user => {
        return new DirectoryEntity(user.identifier, user.type, false);
      }),
      roles
    );

    return this.http.post<boolean>(
      `api/Directory/Role`,
      assignRoleRequest,
      this.httpOptions
    );
  }

  public getUsersByIds(ids: string[]): Observable<Map<string, User>> {
    this.telemetryService.logEvent(Events.GetUsersByIds);

    return this.http.post<User[]>(
      `api/Directory/Users`,
      ids,
      this.httpOptions
    ).pipe(map(users => new Map(users.map(user => [user.externalId.toLowerCase(), user]))));
  }

  public convertRoleIdToRole(roleId: string): Role {
    switch (roleId) {
    case UserService.ADMIN:
      return Role.admin;
    case UserService.DESIGNER:
      return Role.designer;
    case UserService.DATA_WRITER:
      return Role.dataWriter;
    default:
      return Role.dataReader;
    }
  }

  public convertToRoleDisplayName(name: string): string {
    switch (name) {
    case 'Admin':
      return 'Administrator';
    case 'Designer':
      return 'Designer';
    case 'DataWriter':
      return 'Data Writer';
    case 'DataReader':
      return 'Data Reader';
    default:
      return name;
    }
  }

  private isRelationshipFieldAccessible(field: Field, permission: number): boolean {
    if (field.type === FieldType.relationship
      && field.referenceField && field.referenceField.isRbacEnabled) {
      const dataPermissions = this.authService.userProfile && this.authService.userProfile.entityDataPermissions;
      const fieldPermissions = dataPermissions
        && dataPermissions[field.referenceEntity.id]
        && dataPermissions[field.referenceEntity.id].fieldPermissions;
      return fieldPermissions[field.referenceField.id] && fieldPermissions[field.referenceField.id].indexOf(permission) > -1;
    }

    return true;
  }
}
